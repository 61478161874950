import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Container, Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';

const InstallMobilePage = () => {
    const { t } = useTranslation();
    const [maxHeight, setMaxHeight] = useState(0);
    const iosRef = useRef(null);
    const androidRef = useRef(null);

    useEffect(() => {
        const iosHeight = iosRef.current ? iosRef.current.clientHeight : 0;
        const androidHeight = androidRef.current ? androidRef.current.clientHeight : 0;

        setMaxHeight(Math.max(iosHeight, androidHeight));
    }, []);

    return (
        <Layout showDrawer={false} isLandingPage={true}>
            <Box sx={{ minHeight: '100%', backgroundColor: 'peachpuff', pt: { xs: 14, sm: 15 } }}>
                <Container maxWidth="lg">
                    <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}>
                        {t('installMobilePage.title')}
                    </Typography>

                    {/* Top PWA Explanation */}
                    <Grid container spacing={2} justifyContent="center" mt={4}>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 3 }}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    {t('installMobilePage.aboutApp.title')}
                                </Typography>
                                <Typography>
                                    {t('installMobilePage.aboutApp.description')}
                                </Typography>
                            </Paper>
                        </Grid>

                        {/* Apple iOS Instructions */}
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} sx={{ p: 3, minHeight: maxHeight }} ref={iosRef}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    {t('installMobilePage.iosInstructions.title')}
                                </Typography>
                                <ol>
                                    {t('installMobilePage.iosInstructions.steps', { returnObjects: true }).map((step, index) => (
                                        <li key={index}><Typography>{step}</Typography></li>
                                    ))}
                                </ol>
                            </Paper>
                        </Grid>

                        {/* Android Instructions */}
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} sx={{ p: 3, minHeight: maxHeight }} ref={androidRef}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    {t('installMobilePage.androidInstructions.title')}
                                </Typography>
                                <ol>
                                    {t('installMobilePage.androidInstructions.steps', { returnObjects: true }).map((step, index) => (
                                        <li key={index}><Typography>{step}</Typography></li>
                                    ))}
                                </ol>
                            </Paper>
                        </Grid>

                        {/* PWA Advantages */}
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 3 }}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    {t('installMobilePage.advantagesTitle')}
                                </Typography>
                                <Typography sx={{ mt: 2 }}>
                                    <ol>
                                        {t('installMobilePage.advantages', { returnObjects: true }).map((advantage, index) => (
                                            <li key={index}><Typography>{advantage}</Typography></li>
                                        ))}
                                    </ol>
                                </Typography>
                            </Paper>
                            {/* Add some space, somehow adding marginBottom doesnt work for the grid container */}
                            <Box sx={{ height: '16px' }} />
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </Layout>
    );
};

export default InstallMobilePage;