import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box } from '@mui/material';
import { getUserActivities, getTotalActivityPerLanguage, getUserStreak } from '../api/activity';
import { fetchFlashcardMaturityStatistics } from '../api/flashcards';
import { getDailyCharacterCount } from '../api/translator';
import Layout from '../components/Layout';
import useApiErrorHandler from '../hooks/useApiErrorHandler';
import ActivityBarChart from '../components/ActivityBarChart';
import CardCountPieChart from '../components/CardCountPieChart';
import { CurrentLanguageRefreshTriggerContext } from '../providers/IsCurrentLanguageUpdatedProvider';

import streakOn from '../assets/images/streak_on.png';
import streakOff from '../assets/images/streak_off.png';
import ErrorAlert from '../components/ErrorAlert';
import CardWithCenteredContent from '../components/CardWithCenteredContent';
import CircularProgressWithLabel from '../components/CircularProgressWithLabel';
import { useTranslation } from 'react-i18next';

function DashboardPage() {
    const { t } = useTranslation();
    const { currentLanguageRefreshTrigger } = useContext(CurrentLanguageRefreshTriggerContext);
    const [activities, setActivities] = useState([]);
    const [totalActivity, setTotalActivity] = useState([]);
    const [streak, setStreak] = useState(0);
    const [error, setError] = useState([]);
    const [maturityStats, setMaturityStats] = useState([]);
    const [totalFlashcards, setTotalFlashcards] = useState(0);
    const [characterCount, setCharacterCount] = useState({ dailyCharacterCount: 0, dailyLimit: 0 });
    const handleApiError = useApiErrorHandler();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const activitiesResponse = await getUserActivities('week'); // Fetch activities for the past week
                const totalActivityResponse = await getTotalActivityPerLanguage();
                const streakResponse = await getUserStreak();
                const maturityStatsResponse = await fetchFlashcardMaturityStatistics();
                const characterCountResponse = await getDailyCharacterCount();

                setTotalFlashcards(maturityStatsResponse.data.new + maturityStatsResponse.data.learning + maturityStatsResponse.data.young + maturityStatsResponse.data.mature);
                setActivities(activitiesResponse.data);
                setTotalActivity([
                    {
                        'readingTime': totalActivityResponse.data.readingTime,
                        'practicingTime': totalActivityResponse.data.practicingTime
                    },
                ]);
                setStreak(streakResponse.data);
                setMaturityStats([
                    { name: t('common.flashcardTypes.new'), value: maturityStatsResponse.data.new },
                    { name: t('common.flashcardTypes.learning'), value: maturityStatsResponse.data.learning },
                    { name: t('common.flashcardTypes.short-term'), value: maturityStatsResponse.data.young },
                    { name: t('common.flashcardTypes.long-term'), value: maturityStatsResponse.data.mature },
                ]);
                setCharacterCount(characterCountResponse.data);

            } catch (error) {
                setError(handleApiError(error, [t('dashboard.error.general')]));
            }
        };

        fetchUserData();
        // scroll to top
        window.scrollTo(0, 0);
    }, [handleApiError, currentLanguageRefreshTrigger]);

    return (
        <Layout>
            <ErrorAlert errors={error} />
            <Grid container spacing={2} height="100%">
                <Grid item xs={12} md={6} sx={{ height: { xs: '500px', md: '50%' } }}>
                    <CardWithCenteredContent title={streak === 0 ? t('dashboard.noDayStreak') : `${streak} ${t('dashboard.dayStreak')}`}>
                        <img
                            src={streak > 0 ? streakOn : streakOff}
                            alt="Streak Icon"
                            style={{
                                maxWidth: '55%',
                                maxHeight: '55%',
                            }}
                        />
                    </CardWithCenteredContent>
                </Grid>
                <Grid item xs={12} md={6} sx={{ height: { xs: '500px', md: '50%' } }}>
                    <CardWithCenteredContent title={`${totalFlashcards} ${t('dashboard.flashcards')}`}>
                        <CardCountPieChart data={maturityStats} />
                    </CardWithCenteredContent>
                </Grid>

                <Grid item xs={12} md={12} sx={{ height: { xs: '500px', md: '50%' } }}>
                    <CardWithCenteredContent title={t('dashboard.weeklyActivity')}>
                        <ActivityBarChart data={activities} stacked={true} />
                    </CardWithCenteredContent>
                </Grid>
                <Grid item xs={12} md={6} sx={{ height: { xs: '500px', md: '50%' } }}>
                    <CardWithCenteredContent title={t('dashboard.charactersTranslated')}>
                        <CircularProgressWithLabel value={characterCount.dailyCharacterCount} limit={characterCount.dailyLimit} />
                    </CardWithCenteredContent>
                </Grid>
                <Grid item xs={12} md={6} sx={{ height: { xs: '500px', md: '50%' } }}>
                    <CardWithCenteredContent title={t('dashboard.totalActivity')}>
                        <ActivityBarChart data={totalActivity} showDate={false} />
                    </CardWithCenteredContent>
                    {/* Add some space, somehow adding marginBottom doesnt work for the grid container */}
                    <Box sx={{ height: '16px' }} />
                </Grid>
            </Grid>
        </Layout>
    );
}

export default DashboardPage;
