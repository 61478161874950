import { Navigate } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { useTranslation } from 'react-i18next';

const AuthWrapper = ({ children }) => {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated()) {
        return <Navigate to={t('routes.login')} replace />;
    }

    return children;
};

export default AuthWrapper;