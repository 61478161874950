import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useApiErrorHandler() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const errorHandler = useCallback((error, defaultMessage = ['Error']) => {
    const status = error?.response?.status;
    if (status === 400) {
      return error.response.data.errors;
    } else if (status === 401) { // unauthorized
      navigate(t('routes.login'));
      return ['Error: Not authorized'];
    } else {
      console.log(error);
      return defaultMessage;
    }
  }, [navigate]);

  return errorHandler;
};