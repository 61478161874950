import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorAlert from '../components/ErrorAlert';

function ImportDialog({ open, onClose, onImport, error, setError }) {
  const { t } = useTranslation();
  const [importUrl, setImportUrl] = useState("");
  const [isImporting, setIsImporting] = useState(false); // State to track import progress

  useEffect(() => {
    if (open) {
      setError([]);
      setImportUrl("");
      setIsImporting(false); // Reset the importing state when the dialog opens
    }
  }, [open, setError]);

  const handleImportClick = () => {
    setIsImporting(true); // Set importing state to true when import starts
    onImport(importUrl, setIsImporting); // Pass the setIsImporting function to the onImport handler
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isImporting) onClose(); // Prevent closing the dialog while importing
      }}
      maxWidth="md"
      PaperProps={{ style: { minWidth: '50%' } }}
    >
      <DialogTitle>{t('texts.urlDialog.title')}</DialogTitle>
      <DialogContent>
        <ErrorAlert errors={error} />
        <Typography variant="body2" gutterBottom>
          {t('texts.urlDialog.instruction')}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="import-url"
          label={t('texts.urlDialog.label')}
          type="url"
          fullWidth
          variant="outlined"
          placeholder="https://www.example.com/article"
          value={importUrl}
          onChange={(e) => setImportUrl(e.target.value)}
          disabled={isImporting} // Disable the input while importing
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isImporting}>
          {t('texts.urlDialog.cancel')}
        </Button>
        <Button onClick={handleImportClick} disabled={isImporting}>
          {isImporting ? <CircularProgress size={24} /> : t('texts.urlDialog.import')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportDialog;