import React, { useEffect } from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Toolbar, Box, CssBaseline, useTheme } from "@mui/material";
import { Settings, Bookmarks, FitnessCenter, Logout, Article, Dashboard, AutoStories } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBarComponent from './AppBar';
import { useTranslation } from 'react-i18next';
import device from 'current-device';

const drawerWidth = 200;

const Layout = ({ children, showDrawer = true, isLandingPage = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const setFullHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
    
        // Always listen for orientation changes (relevant for mobile devices and tablets)
        window.addEventListener('orientationchange', setFullHeight);
    
        setFullHeight();
    
        // If it's a desktop, listen for resize changes
        console.log("desktop", device.desktop());
        if (device.desktop()) {
            window.addEventListener('resize', setFullHeight);
        }
    
        // Cleanup the listeners on unmount
        return () => {
            if (device.desktop()) {
                window.removeEventListener('resize', setFullHeight);
            }
            window.removeEventListener('orientationchange', setFullHeight);
        };
    
    }, []);
    

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate(t('routes.home'));
    }

    const drawer = (
        <Box>
            <Toolbar />
            <List>
                {[
                    { text: t('drawer.home'), icon: <Dashboard />, path: t('routes.dashboard') },
                    { text: t('drawer.texts'), icon: <Article />, path: t('routes.texts') },
                    { text: t('drawer.books'), icon: <AutoStories />, path: t('routes.books') },
                    { text: t('drawer.flashcards'), icon: <Bookmarks />, path: t('routes.flashcards') },
                    { text: t('drawer.practice'), icon: <FitnessCenter />, path: t('routes.practice') },
                    { text: t('drawer.account'), icon: <Settings />, path: t('routes.account') },
                    { text: t('drawer.logout'), icon: <Logout />, action: handleLogout },
                ].map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton onClick={() => item.path ? navigate(item.path) : item.action()}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    if (isLandingPage) {
        return (
            <Box sx={{ p: 0, m: 0, width: '100vw', height: `calc(var(--vh, 1vh) * 100)` }}>
                <CssBaseline />
                <AppBarComponent
                    navigate={navigate}
                    showDrawer={showDrawer}
                    handleDrawerToggle={handleDrawerToggle}
                    drawerWidth={drawerWidth}
                    landingPage={true}
                />
                {children}
            </Box>
        );
    } else {
        return (
            <Box sx={{ display: 'flex', height: `calc(var(--vh, 1vh) * 100)` }}>
                <CssBaseline />
                <AppBarComponent
                    navigate={navigate}
                    showDrawer={showDrawer}
                    handleDrawerToggle={handleDrawerToggle}
                    drawerWidth={drawerWidth}
                />
                {showDrawer && (
                    <Box
                        component="nav"
                        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                        aria-label="mailbox folders"
                    >
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Drawer
                            variant={isMobile ? "temporary" : "permanent"}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', md: 'block' },
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: drawerWidth,
                                    background: "component.main"
                                },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                )}
                {/* App bar size differs on small screens, so adapt padding to this. */}
                <Box component="main" id="layout" sx={{ flex: 1, p: 2, pt: {xs: 9.5, sm: 10 } }}>
                    {children}
                </Box>
            </Box>
        );
    }
}

export default Layout;
