import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Button, Box, Pagination, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchBooks, deleteBook, uploadBook } from '../api/books';
import BookItem from '../components/BookItem';
import ErrorAlert from '../components/ErrorAlert';
import Layout from '../components/Layout';
import useApiErrorHandler from '../hooks/useApiErrorHandler';
import { CurrentLanguageRefreshTriggerContext } from '../providers/IsCurrentLanguageUpdatedProvider';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '@mui/icons-material';

function BooksPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentLanguageRefreshTrigger } = useContext(CurrentLanguageRefreshTriggerContext);
  const [books, setBooks] = useState([]);
  const [error, setError] = useState([]);
  const navigate = useNavigate();
  const handleApiError = useApiErrorHandler();
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));

  const fetchItems = useCallback(async () => {
    const getNumberOfBooks = () => {
      if (matchesLG) return 12; // screens wider than 1280px
      if (matchesMD) return 8; // screens wider than 960px
      if (matchesSM) return 6;  // screens wider than 600px
      return 4; // screens smaller than 600px
    };
    try {
      const response = await fetchBooks(pageNumber, getNumberOfBooks());
      console.log(response);
      setBooks(response.data.books);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setError(handleApiError(error, [t('books.error.generalFetching')]));
    }
  }, [pageNumber, matchesLG, matchesMD, matchesSM, handleApiError, t]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems, currentLanguageRefreshTrigger, matchesLG, matchesMD, matchesSM]);

  const handleBookClick = (book) => {
    navigate(`${t('routes.reader')}/${t('routes.readerTypes.book')}/${book.id}`);
  };

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setPageNumber(value);
  };

  const handleBookDelete = async (book) => {
    try {
      console.log("Deleting book");
      await deleteBook(book.id);
      if (books.length === 1 && pageNumber > 1) {
        setPageNumber((prev) => prev - 1);
      } else {
        fetchItems();
      }
    } catch (error) {
      setError(handleApiError(error, [t('books.error.generalDelete')])); // Changed from texts.error.generalDelete to books.error.generalDelete
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split('.').pop().toLowerCase();
      if (fileType === 'epub') {
        try {
          await uploadBook(file);
          fetchItems();
        } catch (error) {
          setError(handleApiError(error, [t('books.error.generalUpload')]));
        }
      } else {
        setError([t('books.error.fileFormat')]);
      }
    }
  };

  return (
    <Layout>
      <ErrorAlert errors={error} />
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* Box for the book grid */}
        <Grid container spacing={2}>
          {books.map(book => (
            <Grid item xs={6} sm={6} md={3} lg={2} key={book.id} sx={{ height: { xs: '40vh' } }}>
              <BookItem
                book={book}
                coverImageSasUri={book.coverImageSasUri}
                onDelete={() => handleBookDelete(book)}
                onClick={() => handleBookClick(book)}
              />
            </Grid>
          ))}
        </Grid>

        {/* Box for the button and pagination */}
        <Box>
          {/* Always visible upload button */}
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              component="label"
              color="primary"
              startIcon={<FileUpload />}
            >
              {t('books.uploadBookButton')}
              <input
                type="file"
                hidden
                accept=".epub"
                onChange={handleFileUpload}
              />
            </Button>
          </Box>

          {/* Conditional rendering for pagination */}
          {books.length > 0 && (
            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination count={totalPages} page={pageNumber} onChange={handlePageChange} color="primary" />
            </Box>
          )}
        </Box>
      </Box>
    </Layout>

  );
}

export default BooksPage;