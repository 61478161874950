import React from 'react';
import { Box } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import default styles

const CircularProgressWithLabel = ({value, limit}) => {
  const percentage = value / limit * 100;

  return (
    <Box display="flex" style={{ width: '55%', height: '55%' }}>
      <CircularProgressbar 
        value={percentage} 
        text={`${value} / ${limit}`} 
        styles={buildStyles({
          textSize: '14px',
          pathColor: 'green',
          trailColor: '#ddd',
          textColor: 'green'
        })}
      />
    </Box>
  );
}

export default CircularProgressWithLabel;