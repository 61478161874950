import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { requestPasswordReset } from '../../api/account';
import useApiErrorHandler from '../../hooks/useApiErrorHandler';
import Copyright from '../../components/Copyright';
import { useTranslation } from 'react-i18next';

export default function PasswordRecoveryPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = React.useState([]);
  const handleApiError = useApiErrorHandler();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get('email')) {
      setError([t('passwordRecovery.error.emailRequired')]);
      return;
    }
    console.log({
      email: data.get('email'),
    });
    try {
      const response = await requestPasswordReset(data.get('email'));
      if (response.status === 200) {
        navigate(t('routes.passwordResetEmailSent'));
      } 
    } catch (error) {
      setError(handleApiError(error, [t('passwordRecovery.error.generalError')]));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('passwordRecovery.title')}
        </Typography>
        {error.map((err, index) => (
          <Alert key={index} severity="error" sx={{ mt: 1 }}>{err}</Alert>
        ))}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('passwordRecovery.emailLabel')}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('passwordRecovery.sendResetLinkButton')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href={t('routes.login')} variant="body2">
                {t('passwordRecovery.rememberPasswordLink')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}