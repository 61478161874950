import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const COLORS = ['#0066ff', '#ff8000', '#33cc33', '#006600'];
// color for the empty pie chart
const EMPTY_COLOR = '#ddd';

const CustomTooltip = ({ active, payload, total }) => {
    console.log(payload);
    if (total > 0 && active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };

  const CardCountPieChart = ({ data }) => {
    console.log(data)
    const total = data.reduce((acc, item) => acc + item.value, 0);

    return (
        <ResponsiveContainer width="100%" height="80%">
            <PieChart>
                {total === 0 ? (
                    <Pie
                        data={[{ name: 'No data', value: 1 }]}
                        cx="50%"
                        cy="50%"
                        outerRadius="75%"
                        fill={EMPTY_COLOR}
                        dataKey="value"
                    >
                        <Cell key="cell-0" fill={EMPTY_COLOR} />
                    </Pie>
                ) : (
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius="75%"
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                )}
                <Tooltip content={<CustomTooltip total={total}/> } />
                <Legend
                    payload={data.map(item => ({
                        value: `${item.name} = ${item.value}`,
                        type: 'square',
                        color: COLORS[data.indexOf(item)]
                    }))}
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default CardCountPieChart;