export function mapLanguageToFlagCode(languageCode) {
    switch (languageCode.toUpperCase()) {
      case 'EN':
        return 'US'; // United States flag for English
      case 'UK':
        return 'UA'; // Ukraine flag for UK
      // Add more cases for other language codes if needed
      default:
        // If no specific mapping found, return the same language code
        return languageCode.toUpperCase();
    }
  }
  