import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../api/account';
import useApiErrorHandler from '../../hooks/useApiErrorHandler';
import Copyright from '../../components/Copyright';
import { useTranslation } from 'react-i18next';

export default function PasswordResetPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = React.useState([]);
  const handleApiError = useApiErrorHandler();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get('password') || !data.get('passwordConfirm')) {
      setError([t('passwordReset.error.allFieldsRequired')]);
      return;
    }

    if (data.get('password') !== data.get('passwordConfirm')) {
      setError([t('passwordReset.error.passwordMismatch')]);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const token = urlParams.get('token');

    try {
      const response = await resetPassword(email, token, data.get('password'));
      if (response.status === 200) {
        navigate(t('routes.passwordResetConfirmation'));
      } 
    } catch (error) {
      setError(handleApiError(error, [t('passwordReset.error.general')]));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('passwordReset.title')}
        </Typography>
        {error.map((err, index) => (
          <Alert key={index} severity="error" sx={{ mt: 1, mb: 1 }}>{err}</Alert>
        ))}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('passwordReset.newPasswordLabel')}
            type="password"
            id="password"
            autoComplete="new-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="passwordConfirm"
            label={t('passwordReset.confirmPasswordLabel')}
            type="password"
            id="passwordConfirm"
            autoComplete="new-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('passwordReset.submitButton')}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}