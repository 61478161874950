import React, { useState, useEffect, useCallback, useContext } from "react";
import useApiErrorHandler from "../hooks/useApiErrorHandler";
import { addFlashcard, fetchFlashcards, deleteFlashcard, editFlashcard } from '../api/flashcards';
import { CurrentLanguageRefreshTriggerContext } from '../providers/IsCurrentLanguageUpdatedProvider';
import SuccessAlert from "../components/SuccesAlert";
import ErrorAlert from "../components/ErrorAlert";

// @mui
import {
    Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, Box, Pagination, Stack, useTheme, TablePagination, useMediaQuery
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Layout from '../components/Layout';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SearchIcon from '@mui/icons-material/Search';
import { Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

function FlashcardsPage() {
    const { t } = useTranslation();
    const { currentLanguageRefreshTrigger } = useContext(CurrentLanguageRefreshTriggerContext);
    const [flashcards, setFlashcards] = useState([]);
    const [editingRowId, setEditingRowId] = useState(null);
    const [From, setFrom] = useState("");
    const [To, setTo] = useState("");
    const [FromEdited, setFromEdited] = useState("");
    const [ToEdited, setToEdited] = useState("");
    const [error, setError] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [totalFlashcards, setTotalFlashcards] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [previousSearchKeyword, setPreviousSearchKeyword] = useState("");
    const theme = useTheme();
    const handleApiError = useApiErrorHandler();
    const EnoughSpace = useMediaQuery(theme.breakpoints.up('sm'));

    const handleFetchFlashcards = useCallback(async (newSearchKeyword) => {
        try {
            if (newSearchKeyword !== previousSearchKeyword) {
                setPreviousSearchKeyword(newSearchKeyword);
                setPageNumber(1);
            }
            const response = await fetchFlashcards(newSearchKeyword, pageNumber, rowsPerPage);
            setFlashcards(response.data.flashcards);
            setTotalFlashcards(response.data.totalFlashcards);
        } catch (error) {
            setError(handleApiError(error, [t('flashcards.error.generalFetching')]));
        }
    }, [pageNumber, rowsPerPage, previousSearchKeyword, handleApiError]);

    useEffect(() => {
        handleFetchFlashcards(previousSearchKeyword);
    }, [handleFetchFlashcards, pageNumber, currentLanguageRefreshTrigger]);

    const handleAddFlashcard = async () => {
        try {
            const response = await addFlashcard({ From: From, To: To });
            if (response.status === 200) {
                handleFetchFlashcards(searchKeyword);
                setFrom("");
                setTo("");
                setSuccessMessage(t('flashcards.success.added'));
                setTimeout(() => setSuccessMessage(null), 2000);
            }
        } catch (error) {
            setError(handleApiError(error, [t('common.error.flashcards.generalAdd')]));
            setTimeout(() => setError([]), 2000);
        }
    };

    const handleEditFlashcard = async () => {
        try {
            const response = await editFlashcard(editingRowId, { From: FromEdited, To: ToEdited });
            if (response.status === 200) {
                handleFetchFlashcards(searchKeyword);
                setFromEdited("");
                setToEdited("");
                setSuccessMessage(t('flashcards.success.edited'));
                setTimeout(() => setSuccessMessage(null), 2000);
            }
        } catch (error) {
            setError(handleApiError(error, [t('flashcards.error.generalEdit')]));
            setTimeout(() => setError([]), 2000);
        }
        setEditingRowId(null);
    };

    const handleDeleteFlashcard = async (id) => {
        try {
            const response = await deleteFlashcard(id);
            if (response.status === 200) {
                if (flashcards.length === 1) {
                    // If you remove the only element on a page, we go back to page 1.
                    setPageNumber(1);
                }
                handleFetchFlashcards(searchKeyword);
                setSuccessMessage(t('flashcards.success.deleted'));
                setTimeout(() => setSuccessMessage(null), 2000);
            }
        } catch (error) {
            setError(handleApiError(error, [t('common.error.flashcards.generalDelete')]));
            setTimeout(() => setError([]), 2000);
        }
    };

    const playAudio = (bytes) => {
        if (bytes && bytes.length > 0) {
            const audio = new Audio(`data:audio/wav;base64,${bytes}`);
            audio.play();
        }
    };

    const handlePageChange = (event, newPage) => {
        setPageNumber(newPage + 1);
    };

    const handlePageChangeMobile = (event, value) => {
        setPageNumber(value);
        // scroll to top
        window.scrollTo(0, 0);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPageNumber(1);
    };

    const getMaturityLevel = (level) => {
        switch (level) {
            case 0:
                return t('common.flashcardTypes.new');
            case 1:
                return t('common.flashcardTypes.learning');
            case 2:
                return t('common.flashcardTypes.short-term');
            case 3:
                return t('common.flashcardTypes.long-term');
            default:
                return '';
        }
    }

    return (
        <Layout>
            <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                <ErrorAlert errors={error} />
                <SuccessAlert message={successMessage} />
                {(<Stack mb={2} direction={EnoughSpace ? "row" : "column"} sx={{ bgcolor: "component.main" }}>
                    <TextField
                        id="From"
                        label={t('flashcards.fromTextField')}
                        value={From}
                        sx={{ flexGrow: 1 }}
                        onChange={(e) => setFrom(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Tab') {
                                e.preventDefault(); // Prevent the default Tab behavior
                                // Move the focus to the next input field
                                document.getElementById('To').focus();
                            }
                            if (e.key === 'Enter') {
                                handleAddFlashcard();
                            }
                        }}
                    />
                    <TextField
                        id="To"
                        label={t('flashcards.toTextField')}
                        value={To}
                        sx={{ flexGrow: 1 }}
                        onChange={(e) => setTo(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleAddFlashcard();
                                document.getElementById('From').focus();
                            }
                        }}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddFlashcard}>
                        {t('flashcards.addButton')}
                    </Button>
                </Stack>
                )}
                <Stack mb={2} direction="row" sx={{ bgcolor: "component.main" }}>
                    <TextField
                        id="Search"
                        label={t('flashcards.searchTextField')}
                        value={searchKeyword}
                        sx={{ flexGrow: 1 }}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleFetchFlashcards(searchKeyword);
                            }
                        }}
                    />
                    <Button variant="contained" color="primary" onClick={() => handleFetchFlashcards(searchKeyword)}>
                        <SearchIcon />
                    </Button>

                </Stack>

                {EnoughSpace ? (
                    <>
                        <TableContainer component={Paper} sx={{ bgcolor: "component.main" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '45%', fontWeight: 'bold', color: 'primary.main' }}>{t('flashcards.tableHeader.from')}</TableCell>
                                        <TableCell sx={{ width: '45%', fontWeight: 'bold', color: 'primary.main' }}>{t('flashcards.tableHeader.to')}</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'primary.main' }}>{t('flashcards.tableHeader.actions')}</TableCell>
                                        <TableCell sx={{ width: '5%', fontWeight: 'bold', color: 'primary.main' }}>{t('flashcards.tableHeader.type')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {flashcards.map((flashcard) => (
                                        <TableRow key={flashcard.id} >
                                            <TableCell component="th" scope="row">
                                                {editingRowId === flashcard.id ? (
                                                    <TextField value={FromEdited} fullWidth onChange={(e) => setFromEdited(e.target.value)} />
                                                ) : (
                                                    <>
                                                        {flashcard.from}
                                                        {flashcard.speech && flashcard.speech.length > 0 &&
                                                            <IconButton onClick={() => playAudio(flashcard.speech)}>
                                                                <VolumeUpIcon />
                                                            </IconButton>
                                                        }
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {editingRowId === flashcard.id ? (
                                                    <TextField value={ToEdited} fullWidth onChange={(e) => setToEdited(e.target.value)} />
                                                ) : (
                                                    flashcard.to
                                                )}
                                            </TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap'}}>
                                                {editingRowId === flashcard.id ? (
                                                    <div>
                                                        <IconButton onClick={handleEditFlashcard}>
                                                            <CheckIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => setEditingRowId(null)}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <IconButton
                                                            onClick={() => {
                                                                setEditingRowId(flashcard.id);
                                                                setFromEdited(flashcard.from);
                                                                setToEdited(flashcard.to);
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleDeleteFlashcard(flashcard.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {getMaturityLevel(flashcard.maturityLevel)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            labelRowsPerPage={t('flashcards.rowsPerPage')}
                            component="div"
                            count={totalFlashcards}
                            rowsPerPage={rowsPerPage}
                            page={pageNumber - 1} // The TablePagination component uses a 0-based page index
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <>
                        <Grid container spacing={1}>
                            {flashcards.map((flashcard) => (
                                <Grid item xs={12} key={flashcard.id}>
                                    <Card >
                                        <CardContent sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}>
                                            {editingRowId === flashcard.id ? (
                                                <TextField
                                                    value={FromEdited}
                                                    onChange={(e) => setFromEdited(e.target.value)}
                                                    fullWidth
                                                />
                                            ) : (
                                                <>
                                                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                                                        {flashcard.from}
                                                        {flashcard.speech && flashcard.speech.length > 0 &&
                                                            <IconButton onClick={() => playAudio(flashcard.speech)} >
                                                                <VolumeUpIcon />
                                                            </IconButton>
                                                        }
                                                    </Typography>
                                                </>
                                            )}

                                            {editingRowId === flashcard.id ? (
                                                <TextField
                                                    value={ToEdited}
                                                    onChange={(e) => setToEdited(e.target.value)}
                                                    fullWidth
                                                />
                                            ) : (
                                                <Typography variant="h7" sx={{ mt: 3, textAlign: 'center' }}>
                                                    {flashcard.to}
                                                </Typography>
                                            )}
                                        </CardContent>
                                        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="subtitle1">
                                                {t('flashcards.tableHeader.type')}: {getMaturityLevel(flashcard.maturityLevel)}
                                            </Typography>
                                            <Box>
                                                {editingRowId === flashcard.id ? (
                                                    <>
                                                        <IconButton onClick={handleEditFlashcard}>
                                                            <CheckIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => setEditingRowId(null)}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    <>
                                                        <IconButton
                                                            onClick={() => {
                                                                setEditingRowId(flashcard.id);
                                                                setFromEdited(flashcard.from);
                                                                setToEdited(flashcard.to);
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleDeleteFlashcard(flashcard.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                )}
                                            </Box>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <Pagination
                            count={Math.ceil(totalFlashcards / rowsPerPage)}
                            page={pageNumber}
                            onChange={handlePageChangeMobile}
                            color="primary"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                mt: 2
                            }}
                        />
                    </>
                )}
            </Box>
        </Layout>
    );
}

export default FlashcardsPage;