import { apiRequest } from './apiBase';

export async function fetchTextItems(pageNumber = 1, pageSize = 10) {
    try {
        return await apiRequest('get', `/api/texts?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    } catch (error) {
        throw error;
    }
}

export async function uploadTextItem(file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        return await apiRequest('post', '/api/texts/save-file', formData);
    } catch (error) {
        throw error;
    }
}

export async function deleteTextItem(id) {
    try {
        return await apiRequest('delete', `/api/texts/${id}`);
    } catch (error) {
        throw error;
    }
}

export async function editTextItem(id, title, content, languageLevel) {
    try {
        return await apiRequest('put', `/api/texts/${id}`, { title, content, languageLevel });
    } catch (error) {
        throw error;
    }
}

export async function saveTextContentItem(title, content, languageLevel) {
    try {
        return await apiRequest('post', '/api/texts/save-text', { title, content, languageLevel });
    } catch (error) {
        throw error;
    }
}

export async function importTextFromUrl(url) {
    try {
        return await apiRequest('post', '/api/texts/import-text', { url });
    } catch (error) {
        throw error;
    }
}

export async function fetchTextItemById(id) {
    try {
        return await apiRequest('get', `/api/texts/${id}`);
    } catch (error) {
        throw error;
    }
}

export async function getTextContentForEditing(id) {
    try {
        return await apiRequest('get', `/api/texts/get-text-content-for-editing/${id}`);
    } catch (error) {
        throw error;
    }
}

export async function updateTextItemCfiStart(id, cfiStart) {
    try {
        return await apiRequest('put', `/api/texts/update-cfi-start/${id}`, { cfiStart });
    } catch (error) {
        throw error;
    }
}

export async function updateTextItemFontSizePercentage(id, fontSizePercentage) {
    try {
        return await apiRequest('put', `/api/texts/update-font-size-percentage/${id}`, { fontSizePercentage });
    } catch (error) {
        throw error;
    }
}


