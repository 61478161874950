import { apiRequest } from './apiBase';

export async function recordActivity(type, duration) {
    try {
        return await apiRequest('post', '/api/activity', { type, duration });
    } catch (error) {
        throw error;
    }
}

export async function getUserActivities(duration) {
    try {
        return await apiRequest('get', `/api/activity/${duration}`);
    } catch (error) {
        throw error;
    }
}

export async function getTotalActivity() {
    try {
        return await apiRequest('get', '/api/activity/total');
    } catch (error) {
        throw error;
    }
}

export async function getTotalActivityPerLanguage() {
    try {
        return await apiRequest('get', '/api/activity/total-current-language');
    } catch (error) {
        throw error;
    }
}

export async function getUserStreak() {
    try {
        return await apiRequest('get', '/api/activity/streak');
    } catch (error) {
        throw error;
    }
}
