import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardMedia, CardActionArea, IconButton, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PublicIcon from '@mui/icons-material/Public'; 

const BookItem = ({ book, coverImageSasUri, onDelete, onClick }) => {
  const { t } = useTranslation();

  // Handler to stop propagation and call onDelete
  const handleDeleteClick = (e) => {
    e.stopPropagation(); // This stops the event from bubbling up to the parent elements
    onDelete();
  };

  return (
    <Card sx={{
      position: 'relative',
      height: "100%", // Take full height of the Grid item
    }}>
      <CardActionArea onClick={onClick} sx={{
        height: 1,
        display: 'flex',
        position: 'relative', // Needed to position the pseudo-element and Chip correctly
        '&:hover': {
          // This will affect the CardMedia when CardActionArea is hovered over
          '& .MuiCardMedia-root': {
            opacity: 0.7, // Slightly see-through to darken the image
          },
          '&::before': { // Pseudo-element for the overlay
            content: '""', // The content property is required for before and after pseudo-elements
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
            transition: 'background-color 0.5s ease-in-out', // Smooth transition for the background-color
            pointerEvents: 'none', // Ensures that the overlay doesn't interfere with clicking the CardActionArea
          }
        }
      }}>
        <CardMedia
          component="img"
          sx={{
            width: "100%", // Take full width of CardActionArea
            height: "100%", // Take full height of CardActionArea
            objectFit: 'fill' // Cover the entire area of the card, may crop the image
          }}
          image={coverImageSasUri}
          alt={`Cover of ${book.title}`}
        />
      </CardActionArea>
      {!book.isReadOnly && (
        <IconButton
          onClick={handleDeleteClick}
          size="small"
          sx={{
            position: 'absolute',
            bottom: 5,
            left: 5,
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'grey.300',
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
      {book.accessLevel === 2 && ( // If the book is public
        <Chip
          icon={<PublicIcon />}
          label={t('common.tags.public')}
          sx={{
            position: 'absolute',
            bottom: 5, // Adjust vertical positioning as needed
            right: 5, // Adjust horizontal positioning as needed
            color: 'white',
            bgcolor: 'primary.main', // Adjust background color as needed
            '& .MuiChip-icon': {
              color: 'white', // Adjust icon color as needed
            }
          }}
        />
      )}
    </Card>
  );
};

export default BookItem;
