import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import ReactGA from 'react-ga4';

// Mute the logger in production.
if (process.env.NODE_ENV === 'production') {
    console.log = function () { };
    // Initialize Google Analytics
    ReactGA.initialize("G-EVKN0ZZT0Z");
}

function RouteChangeTracker() {
    const location = useLocation();

    useEffect(() => {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);

    return null; // Component does not render anything
}

function App() {
    return (
        <Router>
            <RouteChangeTracker />
            <AppRoutes />
        </Router>
    );
}

export default App;
