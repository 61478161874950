import React from 'react';
import { Box, Card, CardHeader } from '@mui/material';

const CardWithCenteredContent = ({ title, children, height = '100%' }) => {
  return (
    <Card sx={{ height: height, position: 'relative' }}>
      <CardHeader title={title} />
      <Box position="absolute" top={0} bottom={0} left={0} right={0} display="flex" alignItems="center" justifyContent="center">
        {children}
      </Box>
    </Card>
  );
}

export default CardWithCenteredContent;