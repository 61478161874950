import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container maxWidth="sm" style={{ marginTop: '3rem', textAlign: 'center' }}>
      <Typography variant="h3" color="primary" gutterBottom>
        {t('notFound.errorCode')}
      </Typography>
      <Typography variant="h5" gutterBottom>
        {t('notFound.header')}
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        {t('notFound.description')}
      </Typography>
      <Button variant="contained" color="primary" style={{ marginTop: '1rem' }} onClick={() => navigate(t('routes.home'))}>
        {t('notFound.homeButton')}
      </Button>
    </Container>
  );
}