import { apiRequest } from './apiBase';

export async function fetchUserLanguages() {
    try {
        return await apiRequest('get', '/api/language/user-languages');
    } catch (error) {
        throw error;
    }
}

export async function fetchAvailableLanguages() {
    try {
        return await apiRequest('get', '/api/language/available-languages');
    } catch (error) {
        throw error;
    }
}

export async function fetchAvailableLanguagesForUserToLearn() {
    try {
        return await apiRequest('get', '/api/language/available-languages-for-user-to-learn');
    } catch (error) {
        throw error;
    }
}

export async function fetchNativeLanguage() {
    try {
        return await apiRequest('get', '/api/language/native-language');
    } catch (error) {
        throw error;
    }
}

export async function addUserLanguage(languageId) {
    try {
        return await apiRequest('post', '/api/language/add-language', languageId);
    } catch (error) {
        throw error;
    }
}

export async function setCurrentLanguage(languageId) {
    try {
        return await apiRequest('put', '/api/language/set-current-language', languageId);
    } catch (error) {
        throw error;
    }
}
