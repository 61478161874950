import React, { useState, useEffect } from 'react';
import { Button, Box, TextField, Card, Container, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { saveTextContentItem } from '../api/texts';
import Layout from '../components/Layout';
import useApiErrorHandler from '../hooks/useApiErrorHandler';
import ErrorAlert from '../components/ErrorAlert';
import { useTranslation } from 'react-i18next';
import { useDynamicRows } from '../hooks/useDynamicRows';
import { handlePasteAsPlainText } from '../utils/textUtils';
import { isAdmin } from '../api/account';

function UploadTextPage() {
    const { t } = useTranslation();
    const [text, setText] = useState("");
    const [title, setTitle] = useState("");
    const [error, setError] = useState([]);
    const [isAdminUser, setIsAdminUser] = useState(false);
    const rows = useDynamicRows(290, 28);
    const navigate = useNavigate();
    const handleApiError = useApiErrorHandler();

    const languageLevels = [
        { value: "Unknown", label: t('uploadTexts.levelUnknown') },
        { value: 'A1', label: 'A1' },
        { value: 'A2', label: 'A2' },
        { value: 'B1', label: 'B1' },
        { value: 'B2', label: 'B2' },
        { value: 'C1', label: 'C1' },
        { value: 'C2', label: 'C2' },
    ];
    const [languageLevel, setLanguageLevel] = useState(languageLevels[0].value);

    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const response = await isAdmin();
                setIsAdminUser(response.data.isAdmin);
            } catch (error) {
                setError(handleApiError(error, [t('common.error.adminStatus')]));
            }
        };

        checkAdminStatus();
    }, []);

    const handleTextSave = async () => {
        let errors = [];

        if (title.trim() === "") {
            errors.push(t('commonTexts.error.noTitle'));
        }
        if (text.trim() === "") {
            errors.push(t('commonTexts.error.noText'));
        }

        if (errors.length > 0) {
            setError(errors);
            return;
        }

        try {
            await saveTextContentItem(title, text, languageLevel);
            navigate(t('routes.texts'));
        } catch (error) {
            setError(handleApiError(error, [t('uploadTexts.error.generalSaving')]));
        }
    };

    return (
        <Layout>
            <ErrorAlert errors={error} />
            <Container>
                <Card>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        marginTop={2}
                    >
                        {/* Text Fields */}
                        <Box sx={{ width: '90%', mb: 1 }}>
                            {isAdminUser && (
                                <FormControl fullWidth sx={{ mb: 1 }}>
                                    <InputLabel id="language-level-label">{t('uploadTexts.languageLevel')}</InputLabel>
                                    <Select
                                        labelId="language-level-label"
                                        id="language-level-select"
                                        value={languageLevel}
                                        label={t('uploadTexts.languageLevel')}
                                        onChange={(event) => setLanguageLevel(event.target.value)}
                                    >
                                        {languageLevels.map((level) => (
                                            <MenuItem key={level.value} value={level.value}>
                                                {level.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <TextField
                                id="title"
                                label={t('commonTexts.titleTextField')}
                                variant='outlined'
                                value={title}
                                fullWidth
                                sx={{ mb: 1 }}
                                onChange={(event) => setTitle(event.target.value)}
                                onPaste={(event) => handlePasteAsPlainText(event, setTitle)}
                            />
                            <TextField
                                id="outlined-multiline-static"
                                label={t('commonTexts.textTextField')}
                                multiline
                                rows={rows}
                                variant="outlined"
                                value={text}
                                fullWidth
                                onChange={(event) => setText(event.target.value)}
                                onPaste={(event) => handlePasteAsPlainText(event, setText)}
                            />
                        </Box>
                        {/* Buttons */}
                        <Box mt={2} mb={2}>
                            <Button variant="contained" color="primary" onClick={handleTextSave}>
                                {t('uploadTexts.saveTextButton')}
                            </Button>
                        </Box>
                    </Box>
                </Card>
            </Container>
        </Layout>
    );

}

export default UploadTextPage;