import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function apiRequest(method, url, data = null, withToken = true) {
    const headers = {};
    if (withToken) {
        const token = localStorage.getItem('token');
        headers['Authorization'] = 'Bearer ' + token;
    }
    if (typeof data !== 'object') {
        headers['Content-Type'] = 'application/json';
        data = JSON.stringify(data);
    }
    return axios({
        method,
        url: `${BASE_URL}${url}`,
        data,
        headers,
    });
}