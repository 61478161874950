import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, Menu, MenuItem, ButtonBase } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { fetchAvailableLanguagesForUserToLearn, fetchUserLanguages, addUserLanguage, setCurrentLanguage } from '../api/language';
import { CurrentLanguageRefreshTriggerContext } from '../providers/IsCurrentLanguageUpdatedProvider';
import { useAuth } from '../providers/AuthProvider';
import Flag from '../components/Flag';
import logo from '..//assets/images/logo.png';
import { useTranslation } from 'react-i18next';

function AppBarComponent({ navigate, showDrawer, handleDrawerToggle, drawerWidth, landingPage = false }) {
    const { t } = useTranslation();
    const { toggleRefresh } = useContext(CurrentLanguageRefreshTriggerContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElNested, setAnchorElNested] = useState(null);
    const [userLanguages, setUserLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('currentLanguage'));
    const [languagesAvailable, setLanguagesAvailable] = useState([]);
    const { isAuthenticated } = useAuth();

    const fetchUserLanguagesFromApi = useCallback(async () => {
        try {
            const response = await fetchUserLanguages();
            console.log(response.data)
            setUserLanguages(response.data);
            fetchAvailableLanguagesFromApi(response.data);
        } catch (error) {
            // error handling
        }
    }, []);

    const fetchAvailableLanguagesFromApi = async (userLanguages) => {
        try {
            const response = await fetchAvailableLanguagesForUserToLearn();
            console.log(response.data)

            // Create a set of user language codes for faster lookup
            const userLanguageCodes = new Set(userLanguages.map(lang => lang.code));

            // Filter out the languages that the user is already studying
            const languagesAvailable = response.data.filter(lang => !userLanguageCodes.has(lang.code));

            setLanguagesAvailable(languagesAvailable);
        } catch (error) {
            // error handling
        }
    };

    // trigger fetchUserLanguages function when component mounts
    useEffect(() => {
        if (isAuthenticated()) {
            fetchUserLanguagesFromApi();
        }
    }, [fetchUserLanguagesFromApi]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageClick = async (language) => {
        setSelectedLanguage(language.code);
        console.log(language.id);
        await setCurrentLanguage(language.id);
        localStorage.setItem("currentLanguage", language.code);
        toggleRefresh();
        handleClose();
    };

    const handleAddLanguage = (event) => {
        setAnchorElNested(event.currentTarget);
    };

    const handleCloseNested = () => {
        setAnchorElNested(null);
    };

    const handleBrandClick = () => {
        if (isAuthenticated()) {
            navigate(t('routes.dashboard'));
        } else {
            navigate('/');
        }
    };

    const handleAddLanguageClick = async (id) => {
        try {
            // Call the API to add the language to the user's languages
            await addUserLanguage(id);

            // Refetch the user's languages and available languages
            await fetchUserLanguagesFromApi();
        } catch (error) {
            // Error handling logic here
            console.error("An error occurred while adding a language:", error);
        } finally {
            // Close the nested menu
            handleCloseNested();
        }
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                width: { md: `calc(100% - ${drawerWidth}px)` },
                ml: { md: `${drawerWidth}px` },
                ...(showDrawer ? {} : { ml: 0, width: '100%' }),
            }}
        >
            <Toolbar>
                {!landingPage && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}

                {/* Clickable Brand Icon and Name using ButtonBase */}
                <ButtonBase onClick={handleBrandClick} style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
                    <img src={logo} alt="Brand Logo" style={{ maxHeight: '50px' }} />
                    <Typography variant="h6" noWrap component="div">
                        {t('common.appName')}
                    </Typography>
                </ButtonBase>

                {!isAuthenticated() ? (
                    <Box>
                        <Button color="inherit" onClick={() => navigate(t('routes.login'))}>{t('appBar.signIn')}</Button>
                    </Box>
                ) : (
                    <Box>
                        <IconButton
                            size="large"
                            aria-label="current language"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Flag code={selectedLanguage} name={selectedLanguage} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {userLanguages?.map(language => (
                                <MenuItem key={language.id} onClick={() => handleLanguageClick(language)}>
                                    <Flag code={language.code} name={language.name} />
                                    {language.name}
                                </MenuItem>
                            ))}
                            <MenuItem onClick={handleAddLanguage}>
                                {t('appBar.addLanguage')}
                            </MenuItem>
                            <Menu
                                id="nested-menu"
                                anchorEl={anchorElNested}
                                open={Boolean(anchorElNested)}
                                onClose={handleCloseNested}
                            >
                                {languagesAvailable.map((language, index) => (
                                    <MenuItem key={index} onClick={() => handleAddLanguageClick(language.id)}>
                                        <Flag code={language.code} name={language.name} />
                                        {language.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Menu>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default AppBarComponent;