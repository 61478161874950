import { Card, CardContent, Typography, CardActionArea, CardActions, IconButton, Box, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PublicIcon from '@mui/icons-material/Public';
import { useTranslation } from 'react-i18next';

function TextItem({ textItem, onClick, onEdit, onDelete }) {
  const { t } = useTranslation();

  return (
    <Card sx={{ mb: 1 }}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography variant="h5">
            {textItem.name}
          </Typography>
          {textItem.previewContent.length >= 400 ?
            <Typography variant="body1">
              {textItem.previewContent}...
            </Typography> :
            <Typography variant="body1">
              {textItem.previewContent}
            </Typography>
          }
        </CardContent>
      </CardActionArea>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {textItem.accessLevel === 2 && ( // If its a public text
          <Box sx={{ display: 'flex', gap: 1, p: 1 }}>
            <Chip
              icon={<PublicIcon />}
              label={t('common.tags.public')}
              color="primary"
            />
            <Chip
              label={`${t('common.tags.level')}: ${textItem.languageLevel}`}
              color="secondary"
            />
          </Box>
        )}
        {!textItem.isReadOnly && (
          <CardActions>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </CardActions>
        )}
      </Box>
    </Card>
  );
}

export default TextItem;