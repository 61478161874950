import { apiRequest } from './apiBase';

export async function fetchBooks(pageNumber = 1, pageSize = 10) {
    try {
        return await apiRequest('get', `/api/books?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    } catch (error) {
        throw error;
    }
}

export async function uploadBook(file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        return await apiRequest('post', '/api/books/save-file', formData);
    } catch (error) {
        throw error;
    }
}

export async function deleteBook(id) {
    try {
        return await apiRequest('delete', `/api/books/${id}`);
    } catch (error) {
        throw error;
    }
}

export async function getBookById(id) {
    try {
        return await apiRequest('get', `/api/books/${id}`);
    } catch (error) {
        throw error;
    }
}

export async function updateBookCfiStart(id, cfiStart) {
    try {
        return await apiRequest('put', `/api/books/update-cfi-start/${id}`, { cfiStart });
    } catch (error) {
        throw error;
    }
}

export async function updateBookFontSizePercentage(id, fontSizePercentage) {
    try {
        return await apiRequest('put', `/api/books/update-font-size-percentage/${id}`, { fontSizePercentage });
    } catch (error) {
        throw error;
    }
}
