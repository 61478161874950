import React, { createContext, useState } from 'react';

export const CurrentLanguageRefreshTriggerContext = createContext();

export function CurrentLanguageRereshTriggerProvider({ children }) {
  const [currentLanguageRefreshTrigger, setCurrentLanguageRefreshTrigger] = useState(false);

  const currentLanguageRefreshToggle = () => {
    setCurrentLanguageRefreshTrigger(prevState => !prevState);
  };

  return (
    <CurrentLanguageRefreshTriggerContext.Provider value={{ currentLanguageRefreshTrigger, toggleRefresh: currentLanguageRefreshToggle }}>
      {children}
    </CurrentLanguageRefreshTriggerContext.Provider>
  );
}