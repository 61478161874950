import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Button, Typography, IconButton, LinearProgress, Box, useTheme } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

function Flashcard({ flashcard, onReview, playAudio, progress, totalFlashcards }) {
    const { t } = useTranslation();
    const [isAnswerShown, setIsAnswerShown] = useState(false);
    const progressPercentage = (progress / totalFlashcards) * 100;  // calculate the progress percentage
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setIsAnswerShown(false);
    }, [flashcard]);

    const handleShowAnswer = () => {
        setIsAnswerShown(true);
        if (flashcard.isReversed && flashcard.speech && flashcard.speech.length > 0) {
            playAudio(flashcard.speech);
        }
    };

    if (flashcard === "No more flashcards to review") {
        return (
            <Card>
                <Box minHeight="5vh" my={2}> </Box> 
                <CardContent sx={{ minHeight: "40vh" }}>
                    <Typography variant="h4" sx={{ mt: 3, textAlign: 'center' }}>
                        {t('practiceFlashcards.noMoreCards')}
                    </Typography>
                </CardContent>
                <CardActions sx={{ minHeight: "10vh" }}></CardActions>
            </Card>
        );
    }

    const displayText = flashcard.isReversed ? flashcard.to : flashcard.from;
    const revealText = flashcard.isReversed ? flashcard.from : flashcard.to;
    const playButton = (
        flashcard.speech && flashcard.speech.length > 0 && 
        <IconButton onClick={() => playAudio(flashcard.speech)} >
            <VolumeUpIcon />
        </IconButton>
    );

    return (
        <Card sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Box width="90%" alignSelf="center" minHeight="5vh" my={2}>
                <LinearProgress
                    variant="determinate"
                    value={progressPercentage}
                    sx={{
                        height: '1.5vh',
                        borderRadius: '1.5vh',
                    }}
                />
            </Box>
            <CardContent sx={{ minHeight: "40vh" }}>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                    {displayText}
                    {!flashcard.isReversed && playButton}
                </Typography>
                <Typography variant="body1" sx={{ mt: 10, textAlign: 'center' }}>
                    {isAnswerShown ? revealText : '\u00A0'}
                    {isAnswerShown && flashcard.isReversed && playButton}
                </Typography>
            </CardContent>
            <CardActions sx={{ minHeight: "10vh" }}>
                {!isAnswerShown ? (
                    <Button size="medium" variant="contained" color="primary" onClick={handleShowAnswer}>
                        {t('practiceFlashcards.showAnswerButton')}
                    </Button>
                ) : (
                    <>
                        {isMobile ? (
                            <>
                                <IconButton style={{ backgroundColor: 'red' }} onClick={() => onReview(1)}>
                                    <SentimentVeryDissatisfiedIcon fontSize="medium" />
                                </IconButton>
                                <IconButton style={{ backgroundColor: 'orange' }} onClick={() => onReview(2)}>
                                    <SentimentDissatisfiedIcon fontSize="medium" />
                                </IconButton>
                                <IconButton style={{ backgroundColor: 'lightGreen' }} onClick={() => onReview(3)}>
                                    <SentimentSatisfiedIcon fontSize="medium" />
                                </IconButton>
                                <IconButton style={{ backgroundColor: 'green' }} onClick={() => onReview(4)}>
                                    <SentimentVerySatisfiedIcon fontSize="medium" />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    style={{ backgroundColor: 'red' }}
                                    startIcon={<SentimentVeryDissatisfiedIcon />}
                                    onClick={() => onReview(1)}
                                >
                                    {isMobile ? "" : t('practiceFlashcards.againButton')}
                                </Button>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    style={{ backgroundColor: 'orange' }}
                                    startIcon={<SentimentDissatisfiedIcon />}
                                    onClick={() => onReview(2)}
                                >
                                    {t('practiceFlashcards.hardButton')}
                                </Button>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    style={{ backgroundColor: 'lightGreen' }}
                                    startIcon={<SentimentSatisfiedIcon />}
                                    onClick={() => onReview(3)}
                                >
                                    {t('practiceFlashcards.goodButton')}
                                </Button>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    style={{ backgroundColor: 'green' }}
                                    startIcon={<SentimentVerySatisfiedIcon />}
                                    onClick={() => onReview(4)}
                                >
                                    {t('practiceFlashcards.easyButton')}
                                </Button>
                            </>
                        )}
                    </>
                )}
            </CardActions>
        </Card>
    );
}

export default Flashcard;