import { Navigate, Routes, Route } from 'react-router-dom';
import { useAuth } from './providers/AuthProvider';
import AuthWrapper from './components/AuthWrapper';
import { useTranslation } from 'react-i18next';

import IndexPage from './pages/IndexPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import FlashcardsPage from './pages/FlashcardsPage';
import TextsPage from './pages/TextsPage';
import BooksPage from './pages/BooksPage';
import NotFoundPage from "./pages/NotFoundPage";
import UploadTextPage from './pages/UploadTextPage';
import EditTextsPage from './pages/EditTextsPage';
import PracticeFlashcardsPage from './pages/PracticeFlashcardsPage';
import AccountPage from './pages/AccountPage';
import DashboardPage from './pages/DashboardPage';
import InstallMobilePage from './pages/InstallMobilePage'

// email
import ConfirmEmailPage from './pages/email/ConfirmEmailPage';
import ConfirmEmailFailurePage from './pages/email/ConfirmEmailFailurePage';
import ConfirmEmailSuccessPage from './pages/email/ConfirmEmailSuccesPage';

// password
import PasswordRecoveryPage from './pages/password/PasswordRecoveryPage';
import PasswordResetConfirmationPage from './pages/password/PasswordResetConfirmationPage';
import PasswordResetEmailSentPage from './pages/password/PasswordResetEmailSentPage';
import PasswordResetPage from './pages/password/PasswordResetPage';
import EpubReaderPage from './pages/EpubReaderPage';

function AppRoutes() {
    const { t } = useTranslation(); 
    const { isAuthenticated } = useAuth();

    return (
        <Routes>
            <Route path="/epub-reader/:bookName" element={<AuthWrapper><EpubReaderPage/></AuthWrapper>} />
            <Route path={t('routes.home')} element={isAuthenticated() ? <Navigate to={t('routes.dashboard')} replace /> : <IndexPage />} />
            <Route path={t('routes.login')} element={<LoginPage />} />
            <Route path={t('routes.installMobile')} element={<InstallMobilePage />} />
            <Route path={t('routes.signup')} element={<SignUpPage />} />
            <Route path={t('routes.passwordReset')} element={<PasswordResetPage />} />
            <Route path={t('routes.passwordRecovery')} element={<PasswordRecoveryPage />} />
            <Route path={t('routes.passwordResetEmailSent')} element={<PasswordResetEmailSentPage />} />
            <Route path={t('routes.passwordResetConfirmation')} element={<PasswordResetConfirmationPage />} />
            <Route path={t('routes.confirmEmail')} element={<ConfirmEmailPage />} />
            <Route path={t('routes.confirmEmailSuccess')} element={<ConfirmEmailSuccessPage />} />
            <Route path={t('routes.confirmEmailFailure')} element={<ConfirmEmailFailurePage />} />
            <Route path={t('routes.flashcards')} element={<AuthWrapper><FlashcardsPage /></AuthWrapper>} />
            <Route path={t('routes.texts')} element={<AuthWrapper><TextsPage /></AuthWrapper>} />
            <Route path={t('routes.books')} element={<AuthWrapper><BooksPage /></AuthWrapper>} />
            <Route path={t('routes.practice')} element={<AuthWrapper><PracticeFlashcardsPage /></AuthWrapper>} />
            <Route path={t('routes.uploadText')} element={<AuthWrapper><UploadTextPage /></AuthWrapper>} />
            <Route path={`${t('routes.editText')}/:id`} element={<AuthWrapper><EditTextsPage /></AuthWrapper>} />
            <Route path={`${t('routes.reader')}/:type/:id`} element={<AuthWrapper><EpubReaderPage /></AuthWrapper>} />
            <Route path={t('routes.account')} element={<AuthWrapper><AccountPage /></AuthWrapper>} />
            <Route path={t('routes.dashboard')} element={<AuthWrapper><DashboardPage /></AuthWrapper>} />
            <Route path={t('routes.notFound')} element={<NotFoundPage />} />
        </Routes>
    );
}

export default AppRoutes;