import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Container } from '@mui/material';
import { fetchFlashcard, reviewFlashcard, fetchFlashcardStatistics } from '../api/flashcards';
import Flashcard from '../components/Flashcard';
import Layout from '../components/Layout';
import useApiErrorHandler from '../hooks/useApiErrorHandler';
import { CurrentLanguageRefreshTriggerContext } from '../providers/IsCurrentLanguageUpdatedProvider';
import StatisticsFlashcard from '../components/StatisticsFlashcards';
import useActivityTracker from '../hooks/useActivityTracker';
import { useTranslation } from 'react-i18next';
import ErrorAlert from '../components/ErrorAlert';

function PracticeFlashcardsPage() {
    const { t } = useTranslation();
    const { currentLanguageRefreshTrigger } = useContext(CurrentLanguageRefreshTriggerContext);
    const [flashcard, setFlashcard] = useState(null);
    const [error, setError] = useState([]);
    const [statistics, setStatistics] = useState(null); // add state for statistics
    const [isStarted, setIsStarted] = useState(false); // add state for starting practice
    const [totalFlashcards, setTotalFlashcards] = useState(null);
    const [progress, setProgress] = useState(0);
    const audioRef = useRef(null); 
    const handleApiError = useApiErrorHandler();
    useActivityTracker('practicing');

    // Fetch statistics
    const handleFetchFlashcardStatistics = useCallback(async () => {
        try {
            const response = await fetchFlashcardStatistics();
            setStatistics(response.data);
            setTotalFlashcards(response.data.new + response.data.toReview);
        } catch (error) {
            setError(handleApiError(error, [t('practiceFlashcards.error.generalStatisticsFetching')]));
        }
    }, [handleApiError]);

    useEffect(() => {
        handleFetchFlashcardStatistics();
        setIsStarted(false);
        setProgress(0);
    }, [handleFetchFlashcardStatistics, currentLanguageRefreshTrigger]);

    const handleFetchFlashcard = useCallback(async () => {
        try {
            const response = await fetchFlashcard();
            console.log(response.data);
            setFlashcard(response.data);
            if (!response.data.isReversed && response.data.speech && response.data.speech.length > 0) {
                playAudio(response.data.speech);
            }
        } catch (error) {
            setError(handleApiError(error, [t('practiceFlashcards.error.generalFlashcardFetching')]));
        }
    }, [handleApiError]);

    const handleReviewFlashcard = async (grade) => {
        try {
            await reviewFlashcard(flashcard.id, grade);

            if (grade > 1) {
                setProgress(prevProgress => prevProgress + 1);
            }

            // Check if progress has reached total number of flashcards
            if (progress + 1 >= totalFlashcards) {
                // Wait for one second before showing the completion message, so you see the progress bar complete.
                setTimeout(() => {
                    setFlashcard("No more flashcards to review");
                }, 1000);
            } else {
                // Fetch the next flashcard immediately if there are more flashcards
                handleFetchFlashcard();
            }
        } catch (error) {
            setError(handleApiError(error, [t('practiceFlashcards.error.generalFlashcardReview')]));
        }
    };

    const playAudio = (bytes) => {
        // Stop and release the current audio if it exists
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current = null;
        }

        if (bytes && bytes.length > 0) {
            audioRef.current = new Audio(`data:audio/wav;base64,${bytes}`);
            audioRef.current.play();
        }
    };

    const startPractice = () => {
        setIsStarted(true);
        handleFetchFlashcard(); // fetch the first flashcard
    };

    return (
        <Layout>
            <ErrorAlert errors={error} />
            <Container>
                {isStarted && flashcard
                    ? <Flashcard key={currentLanguageRefreshTrigger} flashcard={flashcard} onReview={handleReviewFlashcard} playAudio={playAudio} progress={progress} totalFlashcards={totalFlashcards} />
                    : <StatisticsFlashcard statistics={statistics} onStart={startPractice} totalFlashcards={totalFlashcards} />}
            </Container>
        </Layout>
    );
}

export default PracticeFlashcardsPage;