import { apiRequest } from './apiBase';

export async function addFlashcard(flashcardDto) {
    try {
        return await apiRequest('post', '/api/flashcards', flashcardDto);
    } catch (error) {
        throw error;
    }
}

export async function fetchFlashcards(searchQuery, pageNumber = 1, pageSize = 10) {
    try {
        return await apiRequest('get', `/api/flashcards?searchQuery=${searchQuery}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
    } catch (error) {
        throw error;
    }
}

export async function editFlashcard(id, flashcardDto) {
    try {
        return await apiRequest('put', `/api/flashcards/${id}`, flashcardDto);
    } catch (error) {
        throw error;
    }
}

export async function deleteFlashcard(id) {
    try {
        return await apiRequest('delete', `/api/flashcards/${id}`);
    } catch (error) {
        throw error;
    }
}

export async function deleteFlashcardByDTO(languageFrom, languageTo) {
    try {
        return await apiRequest('delete', '/api/flashcards', { from: languageFrom, to: languageTo });
    } catch (error) {
        throw error;
    }
}

export async function fetchFlashcard() {
    try {
        return await apiRequest('get', '/api/flashcards/next');
    } catch (error) {
        throw error;
    }
}

export async function reviewFlashcard(id, grade) {
    try {
        return await apiRequest('put', `/api/flashcards/review/${id}`, { grade });
    } catch (error) {
        throw error;
    }
}

export async function fetchFlashcardStatistics() {
    try {
        return await apiRequest('get', '/api/flashcards/statistics');
    } catch (error) {
        throw error;
    }
}

export async function fetchFlashcardMaturityStatistics() {
    try {
        return await apiRequest('get', '/api/flashcards/maturity-statistics');
    } catch (error) {
        throw error;
    }
}
