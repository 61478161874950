import React from 'react';
import Alert from '@mui/material/Alert';

function ErrorAlert({ errors }) {
    return (
        <>
            {errors.map((err, index) => (
                <Alert key={index} severity="error" sx={{ mb: 2 }}>{err}</Alert>
            ))}
        </>
    );
}

export default ErrorAlert;