import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function convertMinutesToTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes - hours * 60);
    const secs = Math.floor((minutes * 60) % 60);

    return `${hours < 10 ? '0' : ''}${hours}:${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
}

const CustomTooltip = ({ active, payload, label, showDate, t }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                {showDate && <p className="label">{`${t('dashboard.toolTipActivityDate')}: ${label}`}</p>}
                <p className="intro">{`${t('dashboard.toolTipActivityReadingTime')}: ${convertMinutesToTime(payload[0].value)}`}</p>
                <p className="intro">{`${t('dashboard.toolTipActivityPracticingTime')}: ${convertMinutesToTime(payload[1].value)}`}</p>
            </div>
        );
    }

    return null;
};

const legendFormatter = (value, t) => {
    switch (value) {
        case 'readingTime':
            return t('dashboard.reading');
        case 'practicingTime':
            return t('dashboard.practicing');
        default:
            return value;
    }
};

function ActivityBarChart({ data, stacked=false, showDate=true }) {
    const { t } = useTranslation();
    const stackId = stacked ? "a" : null;

    return (
        <ResponsiveContainer width="100%" height="80%">
            <BarChart
                data={data}
                margin={{ top: 60, right: 70, left: 15, bottom: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis allowDecimals={false} label={{ value: t('dashboard.labelYaxisActivity'), angle: -90, position: 'insideLeft' }} />
                <Tooltip content={<CustomTooltip showDate={showDate} t={t}/>} />
                <Legend formatter={(value) => legendFormatter(value, t)} />
                <Bar dataKey="readingTime" stackId={stackId} fill="#8884d8" />
                <Bar dataKey="practicingTime" stackId={stackId} fill="#82ca9d" />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default ActivityBarChart;