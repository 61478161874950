import { apiRequest } from './apiBase';

export async function loginUser(data) {
    try {
        return await apiRequest('post', '/api/account/login', data, false);
    } catch (error) {
        throw error;
    }
}

export async function registerUser(data) {
    try {
        return await apiRequest('post', '/api/account/register', data, false);
    } catch (error) {
        throw error;
    }
}

export async function changePassword(currentPassword, newPassword) {
    try {
        return await apiRequest('put', '/api/account/password', { currentPassword, newPassword });
    } catch (error) {
        throw error;
    }
}

export async function deleteUser() {
    try {
        return await apiRequest('delete', '/api/account');
    } catch (error) {
        throw error;
    }
}

export async function getProfile() {
    try {
        return await apiRequest('get', '/api/account/profile');
    } catch (error) {
        throw error;
    }
}

export async function saveProfile(firstName, lastName, email) {
    try {
        return await apiRequest('put', '/api/account/profile', { firstName, lastName, email });
    } catch (error) {
        throw error;
    }
}

export async function requestPasswordReset(email) {
    try {
        return await apiRequest('post', '/api/account/password-recovery', { email });
    } catch (error) {
        throw error;
    }
}

export async function resetPassword(email, token, newPassword) {
    try {
        return await apiRequest('post', '/api/account/reset-password', { email, token, newPassword });
    } catch (error) {
        throw error;
    }
}

export async function isAdmin() {
    try {
        return await apiRequest('get', '/api/account/is-admin');
    } catch (error) {
        throw error;
    }
}
