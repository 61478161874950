import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CurrentLanguageRereshTriggerProvider } from './providers/IsCurrentLanguageUpdatedProvider';
import { AuthProvider } from './providers/AuthProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18nConfig';

const theme = createTheme({
    palette: {
        background: {
            default: '#F5F5F5' // Light gray
            //default: '#fcefe9'
        },
        primary: {
            main: '#e3723a', // Orange
        },
        component: {
            main: '#FAFAFA' // Off-white color
        }
    },

    // Change font here:
    typography: {
        fontFamily: 'Poppins, sans-serif', // Use Poppins font
    }
});

export function AppProvider({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <CurrentLanguageRereshTriggerProvider>
                <I18nextProvider i18n={i18n} >
                    <AuthProvider>
                        {children}
                    </AuthProvider>
                </I18nextProvider>
            </CurrentLanguageRereshTriggerProvider>
        </ThemeProvider>
    );
}