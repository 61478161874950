import React, { useState, useEffect } from 'react';
import { Button, Box, TextField, Card, CardContent, Tabs, Tab, useMediaQuery, useTheme, Grid, CardHeader } from '@mui/material';
import { getProfile, saveProfile, changePassword } from '../api/account';
import Layout from '../components/Layout';
import useApiErrorHandler from '../hooks/useApiErrorHandler';
import ErrorAlert from '../components/ErrorAlert';
import SuccessAlert from '../components/SuccesAlert';
import { useTranslation } from 'react-i18next';
import FlagAccount from '../components/FlagAccount';

function AccountPage() {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);

  return (
    <Layout>
      <ErrorAlert errors={error} />
      <SuccessAlert message={successMessage} />
      {isMobile ? (
        <>
          <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
            <Tab label={t('common.profile')} />
            <Tab label={t('common.password')} />
          </Tabs>
          <Box >
            {value === 0 && <Profile setErrors={setErrors} setSuccessMessage={setSuccessMessage} />}
            {value === 1 && <PasswordChange setErrors={setErrors} setSuccessMessage={setSuccessMessage} />}
          </Box>
        </>
      ) : (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ width: '50%', mb: 2 }}>
            <Profile setErrors={setErrors} setSuccessMessage={setSuccessMessage} />
          </Box>
          <Box sx={{ width: '50%' }}>
            <PasswordChange setErrors={setErrors} setSuccessMessage={setSuccessMessage} />
          </Box>
        </Box>
      )}
    </Layout>
  );
}

function Profile({ setErrors, setSuccessMessage }) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [nativeLanguage, setNativeLanguage] = useState("");
  const handleApiError = useApiErrorHandler();

  // Fetch user profile on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setEmail(response.data.email);
        setNativeLanguage(response.data.nativeLanguage);
      } catch (error) {
        setErrors(handleApiError(error, [t('account.profile.error.generalFetching')]));
      }
    };

    fetchProfile();
  }, [handleApiError]);

  const handleSubmit = async () => {
    try {
      const response = await saveProfile(firstName, lastName, email);
      console.log(response);
      if (response.status === 200) {
        setSuccessMessage(t('account.profile.success.profileUpdated'));
        setTimeout(() => setSuccessMessage(null), 2000);
      }
      setErrors([]);
    } catch (error) {
      setErrors(handleApiError(error, [t('account.profile.error.generalUpdating')]));
    }
  };

  return (
    <Card>
      <CardHeader title={t('common.profile')} />
      <CardContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item style={{ flex: 1 }}>
                <TextField
                  label={t('common.firstName')}
                  variant="outlined"
                  value={firstName}
                  fullWidth
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <TextField
                  label={t('common.lastName')}
                  variant="outlined"
                  value={lastName}
                  fullWidth
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Grid>
              <Grid item >
                <FlagAccount code={nativeLanguage} name={nativeLanguage} />
              </Grid>
            </Grid>
            <TextField
              label={t('common.email')}
              variant="outlined"
              value={email}
              fullWidth
              onChange={(event) => setEmail(event.target.value)}
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" sx={{ pt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {t('account.profile.saveChangesButton')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

function PasswordChange({ setErrors, setSuccessMessage }) {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleApiError = useApiErrorHandler();

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setErrors([t('common.error.passwordsDoNotMatch')]);
      return;
    }

    try {
      const response = await changePassword(oldPassword, newPassword);

      if (response.status === 200) {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setSuccessMessage(t('account.password.success.changed'));
        setTimeout(() => setSuccessMessage(null), 2000);
      }
      setErrors([]);

    } catch (error) {
      setErrors(handleApiError(error, [t('account.password.error.generalChanging')]));
    }
  };

  return (
    <Card>
      <CardHeader title={t('common.password')} />
      <CardContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <TextField
              label={t('account.password.oldTextField')}
              variant="outlined"
              type="password"
              value={oldPassword}
              fullWidth
              onChange={(event) => setOldPassword(event.target.value)}
            />
            <TextField
              label={t('account.password.newTextField')}
              variant="outlined"
              type="password"
              value={newPassword}
              fullWidth
              onChange={(event) => setNewPassword(event.target.value)}
              sx={{ mt: 2 }}
            />
            <TextField
              label={t('account.password.confirmNewTextField')}
              variant="outlined"
              type="password"
              value={confirmPassword}
              fullWidth
              onChange={(event) => setConfirmPassword(event.target.value)}
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" sx={{ pt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePasswordChange}
            >
              {t('account.password.changeButton')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AccountPage;