import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Link from '@mui/material/Link';
import Copyright from '../../components/Copyright';
import { useTranslation } from 'react-i18next';

export default function ConfirmEmailPage() {
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 3, bgcolor: 'secondary.main' }}>
          <MailOutlineIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('confirmEmail.title')}
        </Typography>
        <Typography sx={{ mt: 2, textAlign: 'center' }}>
          {t('confirmEmail.message1')}
        </Typography>
        <Typography sx={{ mt: 2, textAlign: 'center' }}>
          {t('confirmEmail.message2')}
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Link href={t('routes.login')} variant="body2">
            {t('common.backToLogin')}
          </Link>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}