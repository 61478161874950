// Function to handle plain text pasting
export const handlePasteAsPlainText = (event, setText) => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the text content from the Clipboard
    const text = (event.clipboardData || window.clipboardData).getData('text/plain');

    // Update the state with the plain text
    setText(text);
};