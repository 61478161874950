import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { mapLanguageToFlagCode as mapLanguageToCountryCode } from '../utils/languageUtils'; 

function Flag({ name, code }) {
  const countryCode = mapLanguageToCountryCode(code);

  return (
    <>
      {code === 'ar' ? (
        <img
          src={`${process.env.PUBLIC_URL}/flags/arabic_league_flag.svg`}
          alt={name + ' Flag'}
          style={{
            width: '50px',
            height: 'auto',
            marginRight: '10px',
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
          title={name}
        />
      ) : (
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            width: '50px',
            height: 'auto',
            marginRight: '10px',
          }}
          title={name}
        />
      )}
    </>
  );
}

export default Flag;
