import { useRef, useCallback, useEffect } from 'react';
import { recordActivity } from '../api/activity';
import throttle from 'lodash.throttle';

export default function useActivityTracker(activityType) {
    const lastActiveTime = useRef(Date.now());
    const activeTime = useRef(0);
    const inactiveTimeLimit = 60000;
    const sendToServerLimitInMinutes = 2;

    const activityHandler = useCallback(async () => {
        const currentTime = Date.now();
        const timeDifferenceNowAndLastActivity = currentTime - lastActiveTime.current;
        if (timeDifferenceNowAndLastActivity < inactiveTimeLimit) {
            activeTime.current += timeDifferenceNowAndLastActivity;
        }

        if (activeTime.current > sendToServerLimitInMinutes * 60 * 1000) {
            const timeToSendToServer = activeTime.current / 1000;
            activeTime.current = 0;
            try {
                console.log("Duration sending:", timeToSendToServer);
                await recordActivity(activityType, timeToSendToServer);
            } catch (error) {
                console.error("Failed to record activity:", error);
            }
        }

        lastActiveTime.current = currentTime;
    }, [activityType]);

    // Throttle the handler to be called once every 5 seconds at most
    const throttledActivityHandler = throttle(activityHandler, 5000);

    useEffect(() => {
        // Use the throttled version for 'mousemove' and 'scroll'
        window.addEventListener('mousemove', throttledActivityHandler);
        window.addEventListener('keydown', throttledActivityHandler);
        window.addEventListener('scroll', throttledActivityHandler);

        return () => {
            // Use the throttled version when removing too
            window.removeEventListener('mousemove', throttledActivityHandler);
            window.removeEventListener('keydown', throttledActivityHandler);
            window.removeEventListener('scroll', throttledActivityHandler);

            if (activeTime.current > 0) {
                const sendRemainingActivity = async () => {
                    try {
                        await recordActivity(activityType, activeTime.current / 1000);
                        console.log("Remaining active time sent to the server");
                    } catch (error) {
                        console.error("Failed to record remaining activity:", error);
                    }
                };
                sendRemainingActivity();
            }
        };
    }, [activityHandler, activityType]);

    return activityHandler;
}