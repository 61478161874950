import React from 'react';
import Alert from '@mui/material/Alert';

function SuccessAlert({ message }) {
    return (
        <>
            {message && (
                <Alert severity="success" sx={{ mb: 2 }}>
                    {message}
                </Alert>
            )}
        </>
    );
}

export default SuccessAlert;