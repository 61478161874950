import { apiRequest } from './apiBase';

export const translateAndSpeakText = async (text) => {
    try {
        return await apiRequest('post', '/api/translator/translate-and-speak', { TextToTranslate: text, IncludeSpeech: true });
    } catch (error) {
        throw error;
    }
};

export const getDailyCharacterCount = async () => {
    try {
        return await apiRequest('get', '/api/translator/daily-character-count');
    } catch (error) {
        throw error;
    }
};