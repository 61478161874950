import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Button, Typography, Box, Container, Grid, Dialog, DialogContent, Stack } from '@mui/material';
import Layout from '../components/Layout';
import Copyright from '../components/Copyright';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';

import mainPicture from '..//assets/images/main_picture_teksttijger_nieuwe_reader.png';
import spacedRepetitionTiger from '..//assets/images/spaced_repetition_tiger.png';
import statisticsTiger from '..//assets/images/statistieken_tijger_kleiner.png';
import tigerWithBooksSimple from '..//assets/images/tiger_and_books_simple.png';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

export default function IndexPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Determine dialog size based on screen size
    const dialogWidth = isSmallScreen ? '99%' : '60%'; // Adjust these values as needed

    return (
        <Layout showDrawer={false} isLandingPage={true}>
            <Box sx={{ height: '100%', minHeight: "750px", backgroundColor: 'peachpuff', display: 'flex', alignItems: 'center', pt: { xs: 7, sm: 8 } }}>
                <Container maxWidth="lg">
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={7} marginBottom={5}>
                            <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                {t('home.main.title')}
                            </Typography>
                            <Typography variant={isSmallScreen ? "h7" : "h6"}>
                                <ul>
                                    <li>{t('home.main.bodyItem1')}</li>
                                    <li>{t('home.main.bodyItem2')}</li>
                                </ul>
                            </Typography>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            navigate(t('routes.signup'));
                                            ReactGA.event({
                                                category: 'IndexPage',
                                                action: 'Clicked',
                                                label: t('home.getStarted')
                                            });
                                        }}
                                        size="large"
                                    >
                                        {t('home.getStarted')}
                                    </Button>
                                </Stack>
                            </Box>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            navigate(t('routes.installMobile'));
                                            ReactGA.event({
                                                category: 'IndexPage',
                                                action: 'Clicked',
                                                label: t('home.installMobile')
                                            });
                                        }}
                                        startIcon={<InstallMobileIcon />}
                                    >
                                        {t('home.installMobile')}
                                    </Button>
                                    {/* Button to open dialog */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PlayCircleIcon />}
                                        onClick={() => {
                                            handleOpen();
                                            ReactGA.event({
                                                category: 'IndexPage',
                                                action: 'Clicked',
                                                label: t('home.demo')
                                            });
                                        }}
                                    >
                                        {t('home.demo')}
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box display="flex" justifyContent="center">
                                <img src={mainPicture} alt="Landing image" style={{ maxWidth: '100%', height: 'auto' }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={false} // Disable maxWidth to control size via custom styles
                PaperProps={{
                    style: {
                        width: dialogWidth, // Width set by screen size
                        height: 'auto', // Set the height to auto to avoid unnecessary space
                        maxHeight: '100vh', // Prevent the dialog from exceeding the viewport height
                        overflow: 'hidden', // Hide the overflow
                    }
                }}
            >
                <DialogContent style={{
                    position: 'relative', // Parent must be relative if the child is absolute
                    height: '0', // Start with 0 height
                    overflow: 'hidden', // Hide overflow here too
                    // Maintain aspect ratio for 16:9
                    paddingTop: '56.25%',
                }}>
                    <ReactPlayer
                        url="https://www.youtube.com/embed/9r83mYyj50Y?si=i58V10mLwFqGp5b-"
                        playing={true}
                        width="100%"
                        height="100%"
                        style={{
                            position: 'absolute', // Absolutely position the player
                            top: 0,
                            left: 0
                        }}
                        controls={true}
                    />
                </DialogContent>
            </Dialog>

            {/* Feature 1 - Image Right */}
            <Box sx={{ py: 25 }}>
                <Container maxWidth="lg">
                    <Grid container spacing={4} direction="row-reverse" alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6} marginBottom={5}>
                            <Typography variant={isSmallScreen ? "h6" : "h5"} gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                {t('home.feature1.title')}
                            </Typography>
                            <Typography>
                                {t('home.feature1.body')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" justifyContent="center">
                                <img src={tigerWithBooksSimple} alt="Feature 1" style={{ maxWidth: '41%', height: 'auto' }} />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>


            {/* Feature 2 - Image Left */}
            <Box sx={{ backgroundColor: 'peachpuff', py: 25 }}>
                <Container maxWidth="lg">
                    <Grid container spacing={4} alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6} marginBottom={5}>
                            <Typography variant={isSmallScreen ? "h6" : "h5"} gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                {t('home.feature2.title')}
                            </Typography>
                            <Typography>
                                {t('home.feature2.body')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" justifyContent="center">
                                <img src={spacedRepetitionTiger} alt="Feature 1" style={{ maxWidth: '95%', height: 'auto' }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Feature 3 - Image Right */}
            <Box sx={{ py: 25 }}>
                <Container maxWidth="lg">
                    <Grid container spacing={4} direction="row-reverse" alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6} marginBottom={5}>
                            <Typography variant={isSmallScreen ? "h6" : "h5"} gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                {t('home.feature3.title')}
                            </Typography>
                            <Typography>
                                {t('home.feature3.body')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" justifyContent="center">
                                <img src={statisticsTiger} alt="Feature 1" style={{ maxWidth: '60%', height: 'auto' }} />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            {/* Continue adding more features as needed */}

            {/* Footer */}
            <Box sx={{ py: 5, backgroundColor: 'lightgray' }}>
                <Copyright />
                <Box sx={{ mt: 3 }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        info@teksttijger.nl
                    </Typography>
                </Box>
            </Box>
        </Layout>
    );
}