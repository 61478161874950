import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Container, Button, Box, Pagination, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchTextItems, deleteTextItem } from '../api/texts';
import TextItem from '../components/TextItem';
import ErrorAlert from '../components/ErrorAlert';
import Layout from '../components/Layout';
import useApiErrorHandler from '../hooks/useApiErrorHandler';
import { CurrentLanguageRefreshTriggerContext } from '../providers/IsCurrentLanguageUpdatedProvider';
import { useTranslation } from 'react-i18next';
import ImportDialog from '../components/ImportDialog';
import { uploadTextItem, importTextFromUrl } from '../api/texts';
import { AddLink, PostAdd, FileUpload } from '@mui/icons-material';

function TextsPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentLanguageRefreshTrigger } = useContext(CurrentLanguageRefreshTriggerContext);
  const [textItems, setTextItems] = useState([]);
  const [error, setError] = useState([]);
  const navigate = useNavigate();
  const handleApiError = useApiErrorHandler();
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogError, setDialogError] = useState([]);
  const pageSize = 5;

  const fetchItems = useCallback(async () => {
    try {
      const response = await fetchTextItems(pageNumber, pageSize);
      console.log(response);
      setTextItems(response.data.textItems);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setError(handleApiError(error, [t('texts.error.generalFetching')]));
    }
  }, [pageNumber, pageSize, handleApiError]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems, currentLanguageRefreshTrigger]);

  const handleItemClick = (item) => {
    navigate(`${t('routes.reader')}/${t('routes.readerTypes.text')}/${item.id}`);
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const handleItemDelete = async (item) => {
    try {
      console.log("Deleting text item");
      await deleteTextItem(item.id);
      if (textItems.length === 1) {
        // If you remove the only element on a page, we go back to page 1.
        setPageNumber(1);
      }
      // Refresh items after deletion
      fetchItems();
    } catch (error) {
      setError(handleApiError(error, [t('texts.error.generalDelete')]));
    }
  };

  const handleImport = async (url, setIsImporting) => {
    let newErrors = [];

    if (!url.trim()) {
      newErrors.push(t('texts.urlDialog.errors.noUrl'));
    } else {
      try {
        await importTextFromUrl(url);
        fetchItems();
      } catch (error) {
        newErrors.push(handleApiError(error, [t('texts.urlDialog.errors.general')]));
      } finally {
        setIsImporting(false);
      }
    }

    if (newErrors.length > 0) {
      setDialogError(newErrors); // Update the state with the new errors
    } else {
      setOpenDialog(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split('.').pop().toLowerCase();
      if (['txt'].includes(fileType)) {
        try {
          await uploadTextItem(file);
          // Refresh items after uploading
          fetchItems();
        } catch (error) {
          setError(handleApiError(error, [t('uploadTexts.error.generalUpload')]));
        }
      } else {
        setError([t('uploadTexts.error.fileFormat')]);
      }
    }
  };

  const handleItemEdit = (item) => {
    // Navigate to the edit page with the item id
    navigate(`${t('routes.editText')}/${item.id}`);
  };

  return (
    <Layout>
      <ErrorAlert errors={error} />
      <Container>
        {textItems.map(item => (
          <TextItem key={item.id} textItem={item} onDelete={() => handleItemDelete(item)} onEdit={() => handleItemEdit(item)} onClick={() => handleItemClick(item)} />
        ))}
        <Box display="flex" justifyContent="center" mt={2}>
          <Stack direction={isSmallScreen ? "column" : "row"} spacing={2} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              startIcon={<PostAdd />}
              onClick={() => navigate(t('routes.uploadText'))}
            >
              {t('texts.createNewTextButton')}
            </Button>
            <Button
              variant="contained"
              component="label"
              color="primary"
              startIcon={<FileUpload />}
            >
              {t('texts.uploadFromFile')}
              <input type="file" hidden accept=".txt" onChange={handleFileUpload} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddLink />}
              onClick={() => setOpenDialog(true)}
            >
              {t('texts.importFromUrl')} {/* Replace with your text */}
            </Button>
          </Stack>
        </Box>
      </Container>
      <ImportDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onImport={handleImport}
        error={dialogError}
        setError={setDialogError}
      />
      {textItems.length > 0 && (
        <Stack spacing={2} direction="row" justifyContent="center" mt={5}>
          <Pagination count={totalPages} page={pageNumber} onChange={handlePageChange} color="primary" />
        </Stack>
      )}
    </Layout>
  );
}

export default TextsPage;