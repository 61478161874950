import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { mapLanguageToFlagCode as mapLanguageToCountryCode } from '../utils/languageUtils'; 

function FlagAccount({ name, code }) {
  const countryCode = mapLanguageToCountryCode(code);

  return (
    <>
      {code === 'ar' ? (
        <img
          src={`${process.env.PUBLIC_URL}/flags/arabic_league_flag.svg`}
          alt={name + ' Flag'}
          style={{
            width: 'auto',
            height: '56px',
            border: '1px solid #ddd', // Add a border with a light grey color
            boxSizing: 'border-box', // Ensures the border is included in the width/height calculations
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
          title={name}
        />
      ) : (
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            width: 'auto',
            height: '56px',
            border: '1px solid #ddd', // Add a border with a light grey color
            boxSizing: 'border-box', // Ensures the border is included in the width/height calculations
          }}
          title={name}
        />
      )}
    </>
  );
}

export default FlagAccount;
