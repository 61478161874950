import React from 'react';
import { Card, CardContent, CardActions, Button, Typography, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

function StatisticsFlashcard({ statistics, onStart, totalFlashcards }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Box minHeight="5vh" my={2}> </Box> 
            <CardContent sx={{minHeight: "40vh"}}>
                <Typography variant="h4" sx={{ my: 8, textAlign: 'center' }}>
                    {statistics && (
                        <div>
                            <p>{t('practiceFlashcards.newLabel')} {statistics.new}</p>
                            <p>{t('practiceFlashcards.toReviewLabel')} {statistics.toReview}</p>
                        </div>
                    )}
                </Typography>
            </CardContent>
            <CardActions sx={{ minHeight: "10vh" }}>
                <Button size="medium" variant="contained" color="primary" onClick={onStart} disabled={!totalFlashcards}>{t('practiceFlashcards.startButton')}</Button>
            </CardActions>
        </Card>
    );
}

export default StatisticsFlashcard;