import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Epub from 'epubjs';
import {
    Card, IconButton, Menu, MenuItem, Popover, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box,
    Button,
    Grid
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Layout from '../components/Layout';
import { addFlashcard, deleteFlashcardByDTO } from '../api/flashcards';
import { translateAndSpeakText } from '../api/translator';
import { useTranslation } from 'react-i18next';
import useApiErrorHandler from '../hooks/useApiErrorHandler';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import TranslateIcon from '@mui/icons-material/Translate';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme, useMediaQuery } from '@mui/material';
import CustomDialog from '../components/CustomDialog';
import device from 'current-device';

import useActivityTracker from '../hooks/useActivityTracker';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import HistoryIcon from '@mui/icons-material/History';

import { fetchTextItemById, updateTextItemCfiStart, updateTextItemFontSizePercentage } from '../api/texts';
import { getBookById, updateBookCfiStart, updateBookFontSizePercentage } from '../api/books';

const EpubReaderPage = () => {
    const { id, type } = useParams();
    const { t } = useTranslation();
    const theme = useTheme();
    const [rendition, setRendition] = useState(null);
    const [toc, setToc] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [fontSize, setFontSize] = useState(130); // percentage
    const viewerRef = useRef();
    const [translations, setTranslations] = useState(new Map());
    const [combinedWords, setCombinedWords] = useState(new Map());
    const [sounds, setSounds] = useState(new Map());
    const [isLoading, setIsLoading] = useState(true);
    useActivityTracker('reading');

    const [openDialog, setOpenDialog] = useState(false);
    const [errorMessages, setErrorMessage] = useState([]);
    const [errorTitle, setErrorTitle] = useState("");
    const handleApiError = useApiErrorHandler();
    const [menuPosition, setMenuPosition] = useState(null);
    const [flashcards, setFlashcards] = useState(new Map());
    const [selectedKey, setSelectedKey] = useState(null);
    const [bookmarksMenuAnchorEl, setBookmarksMenuAnchorEl] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' is for small screens
    const isBetweenMediumAndLarge = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const [locationRestored, setLocationRestored] = useState(false);
    const [contentReady, setContentReady] = useState(false);
    const [cfiStart, setCfiStart] = useState("");
    const [itemId, setItemId] = useState(null);

    // Define the default styles to apply
    const defaultStyles = {
        'img': {
            'max-width': '100% !important',
            'max-height': '100% !important',
        },
        'div': {
            'max-width': '100% !important',
            'height': 'auto !important',
        },
        'table': {
            'max-width': '100% !important',
            'height': 'auto !important',
        },
        'body': {
            'font-size': `${fontSize}% !important`,
        }
    };

    useEffect(() => {
        async function fetchAndRenderEpub() {
            setIsLoading(true);
            try {

                let response;
                if (type === t('routes.readerTypes.book')) {
                    response = await getBookById(id);
                } else if (type === t('routes.readerTypes.text')) {
                    response = await fetchTextItemById(id);
                } else {
                    console.error("Type is not found");
                    return; // Exit the useEffect if no book is found
                }
                const book = Epub(response.data.sasUri);
                setFontSize(response.data.fontSizePercentage);
                setCfiStart(response.data.cfiStart);
                setItemId(response.data.id);

                const renditionInstance = book.renderTo(viewerRef.current, { width: "100%", height: "100%" });

                book.loaded.navigation.then(({ toc }) => {
                    setToc(toc);
                });

                await book.ready;
                setIsLoading(false);
                setRendition(renditionInstance);
            } catch (error) {
                handleErrorInDialog(error, [t('reader.error.generalFetching')])
            }
        }

        fetchAndRenderEpub();
    }, [id, type]);

    useEffect(() => {
        console.log("second use effect", rendition)
        if (!rendition) return;

        const onRendered = (view) => {
            assignWordIds(rendition);
            addEventListeners();

            setContentReady(true);
        };

        const addEventListeners = () => {
            // Only do this on desktop, IOs it wont work anyways, but Android we want to prevent this.
            if (device.desktop()) {
                rendition.getContents().forEach((content) => {
                    const doc = content.document;
                    doc.addEventListener('mouseup', handleTextSelection);
                });
            }
        };

        const removeEventListeners = () => {
            if (device.desktop()) {
                rendition.getContents().forEach((content) => {
                    const doc = content.document;
                    doc.removeEventListener('mouseup', handleTextSelection);
                });
            }
        };

        rendition.on('rendered', onRendered);
        console.log("cfiStart", cfiStart);

        rendition.display();
        if (!cfiStart) {
            // There is no location to restore.
            setLocationRestored(true);
        }

        // Cleanup: Remove event listeners and then re-attach them.
        return () => {
            rendition.off('rendered', onRendered); // Remove the 'rendered' event listener
            removeEventListeners(); // Remove the other event listeners
        };
    }, [rendition, cfiStart]);

    useEffect(() => {
        if (!rendition) return;

        rendition.themes.default(defaultStyles);
    }, [rendition, fontSize, itemId, type, t]);

    useEffect(() => {
        if (!rendition || !locationRestored) return;


        // Subscribe to the relocated event
        rendition.on("relocated", handleCfiUpdate);

        // Cleanup
        return () => {
            rendition.off('relocated', handleCfiUpdate);
        };
    }, [rendition, locationRestored, itemId, type, t]);

    // This effect is specifically for restoring the location
    useEffect(() => {
        if (!rendition || locationRestored || !contentReady) return;

        if (cfiStart !== "" && !locationRestored) {
            rendition.display(cfiStart).then(() => {
                console.log("Successfully restored location to:", cfiStart);
                // Set the flag to true after successful restoration to prevent loop
                setLocationRestored(true);
            }).catch((error) => {
                console.error("Error restoring location:", error);
                // You might want to handle the error by setting the flag as well
                setLocationRestored(true);
            });
        }
    }, [rendition, locationRestored, contentReady, cfiStart]);

    const handleCfiUpdate = async () => {
        // Save the current CFI
        const currentLocation = rendition.currentLocation();
        if (!currentLocation) return;

        var startCfi = currentLocation.start.cfi;
        console.log("saved cfi", startCfi);

        try {
            if (type === t('routes.readerTypes.book')) {
                await updateBookCfiStart(itemId, startCfi);
            } else if (type === t('routes.readerTypes.text')) {
                await updateTextItemCfiStart(itemId, startCfi);
            }
        } catch (error) {
            console.error('Error updating CFI:', error);
        }
    };

    const nextPage = () => {
        rendition && rendition.next();
    }

    const prevPage = () => {
        rendition && rendition.prev();
    }

    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleBookmarksMenuClick = (event) => {
        setBookmarksMenuAnchorEl(event.currentTarget);
    };

    const handleBookmarksMenuClose = () => {
        setBookmarksMenuAnchorEl(null);
    };

    const handleUpdateFontSize = async (fontSize) => {
        try {
            if (type === t('routes.readerTypes.book')) {
                await updateBookFontSizePercentage(itemId, fontSize);
            } else if (type === t('routes.readerTypes.text')) {
                await updateTextItemFontSizePercentage(itemId, fontSize);
            }
        } catch (error) {
            console.error('Error updating font size percentage:', error);
        }
    };

    const increaseTextSize = () => {
        setFontSize(prevSize => {
            const newSize = Math.min(prevSize + 10, 250);
            handleUpdateFontSize(newSize);
            handleCfiUpdate();
            console.log("font size updated: ", newSize)
            return newSize;
        });
    };

    const decreaseTextSize = () => {
        setFontSize(prevSize => {
            const newSize = Math.max(prevSize - 10, 50); // min 50%
            handleUpdateFontSize(newSize);
            handleCfiUpdate();
            console.log("font size updated: ", newSize)
            return newSize;
        });
    };

    const assignWordIds = (renditionInstance) => {
        console.log("assignWordIds called");
        const contentDocument = renditionInstance.getContents()[0].document;
        console.log(contentDocument);
        let wordCounter = 0;

        //console.log("Before:", contentDocument.body.innerHTML);

        const processNode = (node) => {
            // Check if the current node is a text node and it's not just whitespace
            if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== "") {
                // Get the parent of the current node
                const parent = node.parentNode;

                // Ensure that the parent exists and is not a SCRIPT or STYLE tag
                if (parent && parent.nodeName !== "SCRIPT" && parent.nodeName !== "STYLE") {
                    // This array will hold the new nodes (either word spans or spaces) that we'll create
                    const fragments = [];

                    // Split the text content of the node by spaces to get individual words
                    node.textContent.split(/\s+/).forEach((word, index, array) => {
                        // If the word is not just whitespace
                        if (word.trim() !== "") {
                            // Create a new span element for the word
                            const span = contentDocument.createElement('span');
                            // Assign a unique ID to the span
                            span.setAttribute('data-word-id', wordCounter++);
                            // Set the text content of the span to the word
                            span.textContent = word;
                            // Add the span to the fragments array
                            fragments.push(span);
                        }
                        // If this word is not the last word in the sequence
                        if (index !== array.length - 1) {
                            // Add a space (as a text node) after the word
                            fragments.push(contentDocument.createTextNode(' '));
                        }
                    });

                    // Insert each fragment (either a word span or a space) before the original node
                    fragments.forEach(fragment => parent.insertBefore(fragment, node));
                    // Remove the original node since its content has been replaced by the fragments
                    parent.removeChild(node);
                }
            } else {
                // If the current node is not a text node, recursively process its child nodes
                for (let child of Array.from(node.childNodes)) {
                    processNode(child);
                }
            }
        };

        processNode(contentDocument.body);

        //console.log("After:", contentDocument.body.innerHTML);
    };

    const handleTextSelection = async (event) => {
        event.preventDefault();
        console.log("handle text selection")

        // Ensure rendition is available
        if (!rendition) return;

        // Get the current document from the first content of the rendition
        const contentDocument = rendition.getContents()[0].document;

        // Ensure contentDocument is available
        if (!contentDocument) return;

        // Get the selection from the contentDocument
        const selection = contentDocument.getSelection();
        const selectedText = selection.toString().trim();

        if (!selectedText) return;

        console.log("Selected Text: ", selectedText);

        // Get the range of the selection
        const range = selection.getRangeAt(0);

        // Clone the nodes within the range into a DocumentFragment
        const fragment = range.cloneContents();

        // Get the start and end containers
        const startContainer = range.startContainer;
        const endContainer = range.endContainer;

        // Query the spans within the fragment
        let spans = Array.from(fragment.querySelectorAll("span[data-word-id]:not([data-word-id='translation'])"));
        // Iterate through the spans and log them
        spans.forEach((span, index) => {
            console.log(`Span ${index}:`, span, 'Word ID:', span.dataset.wordId);
        });

        // Replace the first element of the spans array with startContainer if it's a child of a span
        if (
            startContainer.nodeType === Node.TEXT_NODE &&
            startContainer.parentNode.tagName === 'SPAN' &&
            startContainer.parentNode.dataset.wordId != 'translation'
        ) {
            spans[0] = startContainer.parentNode;
        }

        // Store the initial text of endContainer
        const endContainerText = endContainer.textContent;

        // Replace the last element of the spans array with endContainer if it's a child of a span
        if (
            endContainer.nodeType === Node.TEXT_NODE &&
            endContainer.parentNode.tagName === 'SPAN' &&
            endContainer.parentNode.dataset.wordId != 'translation'
        ) {
            const parentNode = endContainer.parentNode;
            const parentNodeText = parentNode.textContent;

            // If the parentNode text starts with endContainer text, replace the last element of spans array
            if (parentNodeText.startsWith(endContainerText)) {
                spans[spans.length - 1] = parentNode;
            }
        }

        if (spans.length === 0) {
            setOpenDialog(true);
            setErrorTitle(t('reader.error.translateATranslation.title'));
            setErrorMessage([t('reader.error.translateATranslation.message')]);
            return;
        }

        console.log('Selection of spans', spans);

        // Iterate through the spans and log them
        spans.forEach((span, index) => {
            console.log(`Span ${index}:`, span, 'Word ID:', span.dataset.wordId);
        });

        let firstId = Number(spans[0].dataset.wordId);
        let lastId = Number(spans[spans.length - 1].dataset.wordId);

        console.log('First id before fuse: ', firstId);
        console.log('Last id before fuse: ', lastId);
        console.log('A new word/phrase was found.');

        // Use firstId and lastId to construct the combinedWords for translation
        let combinedWordsForTranslation = getCombinedWords(firstId, lastId);
        console.log("combinedWordsForTranslation: ", combinedWordsForTranslation);

        // If a word or words before or after selection was already marked for translation, we have to fuse them together.
        firstId = updateFirstIdForAfterFuse(firstId);
        lastId = updateLastIdForAfterFuse(lastId);

        const combinedWordsForTranslationAfterFuse = getCombinedWords(firstId, lastId);
        console.log("combinedWordsForTranslationAfterFuse: ", combinedWordsForTranslationAfterFuse);

        console.log('First id after fuse: ', firstId);
        console.log('Last id after fuse: ', lastId);
        console.log("Combined words after fuse:", combinedWordsForTranslationAfterFuse);

        console.log(combinedWords);
        let fusationOfWords = false;

        // Check if the combined words have changed after trying to fuse, if so we have fusation of words
        if (combinedWordsForTranslation !== combinedWordsForTranslationAfterFuse) {
            fusationOfWords = true;

            //TODO: visibleTranslation hierzetten

            combinedWordsForTranslation = combinedWordsForTranslationAfterFuse;
        }

        console.log("Words use for translation:", combinedWordsForTranslation);

        try {
            const response = await translateAndSpeakText(combinedWordsForTranslation);

            console.log(response.data)
            const audio = new Audio(`data:audio/wav;base64,${response.data.speechData}`);
            audio.play();

            setTranslations(prevTranslations => new Map(prevTranslations).set([firstId, lastId].toString(), response.data.translatedText));
            setCombinedWords(prevCombinedWords => new Map(prevCombinedWords).set([firstId, lastId].toString(), combinedWordsForTranslation));
            setSounds(prevSounds => new Map(prevSounds).set([firstId, lastId].toString(), response.data.speechData));

            // Remove previous translation(s) in case of fusation words
            console.log("Fustation of words:", fusationOfWords)
            removeTranslationSpans(firstId, lastId);
            // Highlight the selected words
            highlightSpans(firstId, lastId);

            // Add translation with button
            insertTranslationAndButton(firstId, lastId, response.data.translatedText);
        } catch (error) {
            handleErrorInDialog(error, [t('reader.error.generalTranslating')])
        }
        // Clear the selection
        selection.removeAllRanges();
    }

    const handleErrorInDialog = (error, defaultMessage) => {
        setOpenDialog(true);
        const errorMessages = handleApiError(error, defaultMessage);

        setErrorTitle("Error");
        setErrorMessage([errorMessages]);

        if (errorMessages.length === 1) {
            if (errorMessages[0] === t('common.limits.translation')) {
                setErrorTitle(t('reader.error.translateLimitDialog.title'));
                setErrorMessage([t('reader.error.translateLimitDialog.message')]);
            }
            if (errorMessages[0] === t('common.limits.flashcards')) {
                setErrorTitle(t('reader.error.flashcardsLimitDialog.title'));
                setErrorMessage([t('reader.error.flashcardsLimitDialog.message')]);
            }
        }
    }

    const highlightSpans = (firstId, lastId) => {
        // Ensure rendition is available
        if (!rendition) return;

        // Get the current document from the first content of the rendition
        const contentDocument = rendition.getContents()[0].document;

        // Ensure contentDocument is available
        if (!contentDocument) return;

        // Get all spans in the range between firstId and lastId
        const spansToHighlight = Array.from(contentDocument.querySelectorAll(`span[data-word-id]`))
            .filter(span => {
                const id = Number(span.getAttribute('data-word-id'));
                return id >= firstId && id <= lastId;
            });

        // Apply highlighting styles to all spans in the range
        spansToHighlight.forEach(span => {
            span.style.backgroundColor = 'rgb(227, 114, 58)';
        });
    };

    const removeHighlightFromSpans = (firstId, lastId) => {
        // Ensure rendition is available
        if (!rendition) return;

        // Get the current document from the first content of the rendition
        const contentDocument = rendition.getContents()[0].document;

        // Ensure contentDocument is available
        if (!contentDocument) return;

        // Get all spans in the range between firstId and lastId
        const spansToUnhighlight = Array.from(contentDocument.querySelectorAll(`span[data-word-id]`))
            .filter(span => {
                const id = Number(span.getAttribute('data-word-id'));
                return id >= firstId && id <= lastId;
            });

        // Remove highlighting styles from all spans in the range
        spansToUnhighlight.forEach(span => {
            span.style.backgroundColor = ''; // Reset the background color
        });
    };

    const insertTranslationAndButton = (firstId, lastId, translationText) => {
        // Ensure rendition is available
        if (!rendition) return;

        // Get the current document from the first content of the rendition
        const contentDocument = rendition.getContents()[0].document;

        // Ensure contentDocument is available
        if (!contentDocument) return;

        // Find the last span using lastId
        const lastSpan = contentDocument.querySelector(`span[data-word-id="${lastId}"]`);

        // Ensure lastSpan is available
        if (!lastSpan) return;

        // Create a new element to hold the translation 
        const translationSpan = contentDocument.createElement('span');
        translationSpan.style.color = theme.palette.primary.main;  // Replace with your primary color
        translationSpan.setAttribute('data-translation-id', `${firstId}-${lastId}`);
        translationSpan.setAttribute('data-word-id', `translation`);
        translationSpan.textContent = " " + translationText + " ";

        // Append translationSpan after lastSpan
        lastSpan.parentNode.insertBefore(translationSpan, lastSpan.nextSibling);

        // Create the hamburger button
        const hamburgerButton = contentDocument.createElement('button');
        hamburgerButton.textContent = '☰';  // Or use an icon/image
        hamburgerButton.style.verticalAlign = 'middle';
        hamburgerButton.style.marginBottom = '3px';  // Adjust as needed
        hamburgerButton.setAttribute('data-button-id', `hamburger-${firstId}-${lastId}`);

        if (device.desktop()) {
            // Append hamburger button after translationSpan
            translationSpan.parentNode.insertBefore(hamburgerButton, translationSpan.nextSibling);
            hamburgerButton.addEventListener('click', handleButtonClick);

            function handleButtonClick(event) {
                event.preventDefault();
                console.log('Hamburger clicked', event);

                // Get the bounding rectangle of the hamburger button
                const buttonRect = event.target.getBoundingClientRect();

                // Calculate position: bottom-right corner of the button
                const buttonLeft = buttonRect.left;
                const buttonTop = buttonRect.bottom;

                // Get the bounding rectangle of the viewer
                const viewerRect = document.getElementById('viewer').getBoundingClientRect();

                console.log("viewerRect left", viewerRect.left);
                console.log("viewerRect top", viewerRect.top);
                console.log("viewerRect right", viewerRect.right);
                console.log("viewerRect bottom", viewerRect.bottom);
                console.log("viewerRect width", viewerRect.width);

                // Calculate position relative to the card
                let left = buttonLeft + viewerRect.left;
                const top = buttonTop + viewerRect.top;
                console.log("left before", left);

                while (left - viewerRect.width > viewerRect.left) {
                    left = left - viewerRect.width;
                }

                console.log('Calculated position', { left, top });

                // Get the data-button-id of the clicked button
                const buttonId = event.target.dataset.buttonId;
                console.log('Clicked button data-button-id:', buttonId);

                // Extract firstId and lastId from the buttonId string
                const [_, firstId, lastId] = buttonId.split('-').map(Number); // Using _ as a placeholder for the unused part of the split string
                console.log('Extracted IDs:', { firstId, lastId });

                // Update the state with the extracted IDs
                setSelectedKey([firstId, lastId].toString());

                // Set position
                setMenuPosition({ left, top });
            };
        }
    };

    const removeTranslationSpans = (firstId, lastId) => {
        // Ensure rendition is available
        if (!rendition) return;

        // Get the current document from the first content of the rendition
        const contentDocument = rendition.getContents()[0].document;

        // Ensure contentDocument is available
        if (!contentDocument) return;

        // Define a function to extract IDs from a string
        const extractIds = str => str.split('-').slice(-2).map(Number);

        // Get all elements with a data-translation-id attribute and data-button-id attribute
        const elementsToRemove = Array.from(contentDocument.querySelectorAll(`span[data-translation-id], button[data-button-id]`))
            .filter(element => {
                const [elemFirstId, elemLastId] = extractIds(element.dataset.translationId || element.dataset.buttonId);
                return (elemFirstId >= firstId && elemFirstId <= lastId) || (elemLastId >= firstId && elemLastId <= lastId);
            });

        // Remove all spans and buttons in the range
        elementsToRemove.forEach(element => {
            element.remove();
        });
    };

    const getCombinedWords = (firstId, lastId) => {
        // Ensure rendition is available
        if (!rendition) return "";

        // Get the current document from the first content of the rendition
        const contentDocument = rendition.getContents()[0].document;

        // Ensure contentDocument is available
        if (!contentDocument) return "";

        // Get the word spans in the ID range
        const wordSpans = Array.from(contentDocument.querySelectorAll("span[data-word-id]:not([data-word-id='translation'])"))
            .filter(span => {
                const id = Number(span.getAttribute('data-word-id'));
                return id >= firstId && id <= lastId;
            });
        console.log("wordSpans", wordSpans);

        // Combine the words
        return wordSpans.map(span => span.textContent).join(' ');
    };

    const updateFirstIdForAfterFuse = (firstId) => {
        // Ensure rendition is available
        if (!rendition) return firstId;

        // Get the current document from the first content of the rendition
        const contentDocument = rendition.getContents()[0].document;

        // Ensure contentDocument is available
        if (!contentDocument) return firstId;

        // Check previous words to see if they are part of the same translation
        while (contentDocument.querySelector(`span[data-word-id="${firstId - 1}"]`)?.style.backgroundColor === 'rgb(227, 114, 58)') {
            firstId--;
        }

        return firstId;
    };

    const updateLastIdForAfterFuse = (lastId) => {
        // Ensure rendition is available
        if (!rendition) return lastId;

        // Get the current document from the first content of the rendition
        const contentDocument = rendition.getContents()[0].document;

        // Ensure contentDocument is available
        if (!contentDocument) return lastId;

        // Check next words to see if they are part of the same translation
        while (contentDocument.querySelector(`span[data-word-id="${lastId + 1}"]`)?.style.backgroundColor === 'rgb(227, 114, 58)') {
            lastId++;
        }

        return lastId;
    };

    async function handleBookmarkClick(key) {
        const combinedWordsForTranslation = combinedWords.get(key);
        const translation = translations.get(key);
        const speech = sounds.get(key);

        if (flashcards.get(key)) {
            console.log("Removing word");
            try {
                await deleteFlashcardByDTO(combinedWordsForTranslation, translation);
                setFlashcards(prevFavoredTranslations => new Map(prevFavoredTranslations).set(key, false));
            } catch (error) {
                handleErrorInDialog(error, [t('common.error.flashcards.generalDelete')]);
            }
        } else {
            console.log("Adding word");
            try {
                await addFlashcard({ from: combinedWordsForTranslation, to: translation, speech: speech });
                setFlashcards(prevFavoredTranslations => new Map(prevFavoredTranslations).set(key, true));
            } catch (error) {
                handleErrorInDialog(error, [t('common.error.flashcards.generalAdd')]);
            }
        }
        console.log(flashcards);
    };

    function handlePlaySoundClick(key) {
        const sound = sounds.get(key);

        const audio = new Audio(`data:audio/wav;base64,${sound}`);
        audio.play();
    }

    const handlePlaySound = (soundData) => {
        const audio = new Audio(`data:audio/wav;base64,${soundData}`);
        audio.play();
    };

    function isInRangeOfOtherCombinedWords(combinedWords, firstId, lastId) {
        for (let key of combinedWords.keys()) {
            const [rangeStart, rangeEnd] = key.split(',').map(Number);
            console.log(rangeStart, rangeEnd);
            if (!(firstId === rangeStart && lastId === rangeEnd) && firstId >= rangeStart && lastId <= rangeEnd) {
                return true; // The pair is within the range of this key and is a subset
            }
        }
        return false; // No ranges in the map include the pair
    }

    const handleRemoveTranslation = (key) => {
        console.log(key);

        setTranslations(prevTranslations => {
            const updatedTranslations = new Map(prevTranslations);
            updatedTranslations.delete(key);
            return updatedTranslations;
        });

        setCombinedWords(prevCombinedWords => {
            const updatedCombinedWords = new Map(prevCombinedWords);
            updatedCombinedWords.delete(key);
            return updatedCombinedWords;
        });

        setSounds(prevSounds => {
            const updatedSounds = new Map(prevSounds);
            updatedSounds.delete(key);
            return updatedSounds;
        });

        setFlashcards(prevFlashcards => {
            const updatedFlashcards = new Map(prevFlashcards);
            updatedFlashcards.delete(key);
            return updatedFlashcards;
        });

        const [firstId, lastId] = key.split(',').map(Number);
        if (!isInRangeOfOtherCombinedWords(combinedWords, firstId, lastId)) {
            // Only remove if it doesnt have an overlaying word(s) - translation pair (created after a fusation)
            removeTranslationSpans(firstId, lastId);
            removeHighlightFromSpans(firstId, lastId);
        }

        setMenuPosition(null); // Close the menu     
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const popoverStyles = isMobile
        ? {}
        : {
            width: '50vw',      // fixed width for non-mobile screens
            //height: '80vh',     // fixed height for non-mobile screens (adjust as needed)
            overflow: 'auto'
        };

    return (
        <Layout>
            <Card id="card" style={{ display: 'flex', position: 'relative', flex: 1, height: '100%', justifyContent: 'center', alignItems: "center", overflow: 'hidden' }}>
                <div ref={viewerRef} style={{ height: '80%', width: isBetweenMediumAndLarge ? '60vw' : '80vw' }} id="viewer"></div>

                {isLoading || !locationRestored ? (
                    <Box display="flex" justifyContent="center" backgroundColor="white" alignItems="center" position="absolute" top="0" left="0" bottom="0" right="0">
                        <CircularProgress />
                    </Box>
                ) : (
                    null
                )}

                <Box position="absolute" top={0} left={0} p={1}>
                    <IconButton onClick={handleMenuClick}><MenuIcon /></IconButton>
                    <IconButton onClick={handleBookmarksMenuClick}><HistoryIcon /></IconButton>
                </Box>

                <Box position="absolute" top={0} right={0} p={1}>
                    <IconButton onClick={increaseTextSize}><AddIcon /></IconButton>
                    <IconButton onClick={decreaseTextSize}><RemoveIcon /></IconButton>
                </Box>

                {/* Navigation buttons and translate button */}
                <Grid container position="absolute" bottom={5} left={0} right={0} alignItems="center" justifyContent="space-between" >
                    <Grid item>
                        <IconButton onClick={prevPage} style={{ fontSize: 40 }}>
                            <NavigateBeforeIcon style={{ fontSize: 40 }} />
                        </IconButton>
                    </Grid>

                    <Grid item xs>
                        <Box display="flex" justifyContent="center">
                            {/* Render Translate Button only if not desktop */}
                            {!device.desktop() && (
                                <Button variant="contained" color="primary" startIcon={<TranslateIcon />} onClick={handleTextSelection}>
                                    {t('reader.translateButton')}
                                </Button>
                            )}
                        </Box>
                    </Grid>

                    <Grid item>
                        <IconButton onClick={nextPage} style={{ fontSize: 40 }}>
                            <NavigateNextIcon style={{ fontSize: 40 }} />
                        </IconButton>
                    </Grid>
                </Grid>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                        style: {
                            maxHeight: '60vh',
                            overflow: 'auto'
                        },
                    }}
                >
                    {toc.map((item, index) => (
                        <MenuItem key={index} onClick={() => {
                            rendition.display(item.href);
                            handleMenuClose();
                        }}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Menu>

                {/* Bookmarks Menu */}
                <Popover
                    open={Boolean(bookmarksMenuAnchorEl)}
                    anchorEl={bookmarksMenuAnchorEl}
                    onClose={handleBookmarksMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: {
                            ...popoverStyles,
                            maxHeight: '60vh',
                        }
                    }}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableCell style={{ width: '50%' }}>{t('reader.table.from')}</TableCell>
                                <TableCell style={{ width: '50%' }}>{t('reader.table.to')}</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                    {t('reader.table.actions')}
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {Array.from(translations.keys()).reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((key) => (
                                    <TableRow key={key}>
                                        <TableCell style={{ width: '50%' }}>
                                            {combinedWords.get(key)}
                                            <IconButton onClick={() => handlePlaySound(sounds.get(key))}>
                                                <VolumeUpIcon />
                                            </IconButton>
                                        </TableCell >
                                        <TableCell style={{ width: '50%' }} >{translations.get(key)}</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                            <IconButton onClick={() => handleBookmarkClick(key)}>
                                                {
                                                    flashcards.get(key) ?
                                                        <BookmarkAddedIcon style={{ color: "green" }} /> :
                                                        <BookmarkAddIcon />
                                                }
                                            </IconButton>
                                            <IconButton onClick={() => handleRemoveTranslation(key)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        labelRowsPerPage={t('reader.table.rowsPerPage')}
                        component="div"
                        count={translations.size}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Popover>

                {/* MUI Menu */}
                {/* <TranslationMenu anchorPosition={menuPosition} onClose={closeMenu} /> */}
                <Menu
                    open={Boolean(menuPosition)}
                    onClose={() => setMenuPosition(null)}
                    anchorReference="anchorPosition"
                    anchorPosition={menuPosition ? { top: menuPosition.top, left: menuPosition.left } : undefined}
                >
                    <MenuItem onClick={() => handleBookmarkClick(selectedKey)}>
                        <IconButton disableRipple>
                            {
                                flashcards.get([selectedKey].toString()) ?
                                    <BookmarkRemoveIcon /> :
                                    <BookmarkAddIcon />
                            }
                        </IconButton>
                        {
                            flashcards.get([selectedKey].toString()) ?
                                t('reader.menu.deleteFlashcard') :
                                t('reader.menu.addFlashcard')
                        }
                    </MenuItem>
                    <MenuItem onClick={() => handlePlaySoundClick(selectedKey)}>
                        <IconButton aria-label="play sound" disableRipple>
                            <VolumeUpIcon />
                        </IconButton>
                        {t('reader.menu.playSound')}
                    </MenuItem>
                    <MenuItem onClick={() => handleRemoveTranslation([selectedKey].toString())}>
                        <IconButton color="red" aria-label="delete translation" disableRipple>
                            <DeleteIcon />
                        </IconButton>
                        {t('reader.menu.deleteTranslation')}
                    </MenuItem>
                </Menu>

            </Card>

            <CustomDialog
                open={openDialog}
                onClose={handleCloseDialog}
                title={errorTitle}
                message={errorMessages}
            />

        </Layout>
    );
};

export default EpubReaderPage;
