import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const CustomDialog = ({ open, onClose, title, message, actions }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {message.map((msg, index) => (
                    <DialogContentText key={index} id={`alert-dialog-description-${index}`}>
                        {msg}
                    </DialogContentText>
                ))}
            </DialogContent>

            <DialogActions>
                {actions ? actions : <Button onClick={onClose} color="primary" autoFocus>OK</Button>}
            </DialogActions>
        </Dialog>
    );
}

export default CustomDialog;