import { useState, useEffect } from 'react';

export const useDynamicRows = (reservedHeight = 200, approximateRowHeight = 28) => {
  const [rows, setRows] = useState(40); // Default value

  useEffect(() => {
    const calculateRows = () => {
      const availableHeight = window.innerHeight - reservedHeight;
      const dynamicRows = Math.floor(availableHeight / approximateRowHeight);
      setRows(dynamicRows);
    };

    // Initialize
    calculateRows();

    // Update on resize
    window.addEventListener('resize', calculateRows);

    return () => {
      window.removeEventListener('resize', calculateRows);
    };
  }, [reservedHeight, approximateRowHeight]);

  return rows;
};